import Vue from "vue";
import Vuex from "vuex";
import { store as app } from "@/app/index";

Vue.use(Vuex);

import state from "./state";
import * as actions from "./actions";
import * as getters from "./getters";
import * as mutations from "./mutations";

export default new Vuex.Store({
  state,
  actions,
  mutations,
  getters,
  modules: {
    app,
  },
});
