import { Home, Refresher } from "./components";

export default [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: {
      title: "HOME",
      requiredAuth: true,
    },
  },
  {
    path: "/refresh",
    name: "refresher",
    component: Refresher,
    meta: {
      title: "Refresher",
      requiredAuth: false,
    },
  },

  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
];
