<template>
  <v-col cols="12" :md="CanRender ? 7 : 12" class="fill-height border-right">
    <vue-editable-grid
      class="mt-2 grid"
      ref="grid"
      id="grid"
      :column-defs="columnDefs"
      :row-data="rows"
      :only-border="true"
      :enable-filters="$store.getters['app/config/enableFilters']"
      :breakLine="$store.getters['app/config/breakLine']"
      :breakLineWordLimit="$store.getters['app/config/breakLineWordLimit']"
      :breakLineEllipsis="$store.getters['app/config/breakLineEllipsis']"
      :tab2Column="$store.getters['app/config/tab2Column']"
      row-data-key="sn"
      :itemHeight="fontSize * 2"
      :style="gridStyle"
      @cell-updated="cellUpdated"
      @row-selected="rowSelected"
      @context-menu="onContextMenu"
      @contextmenu="onContextMenu"
      @keyup="onKeyUp"
    >
      <template v-slot:header>
        <v-text-field
          dense
          flat
          :single-line="true"
          class="mt-2 mx-2 input-100px"
          append-outer-icon="mdi-content-save"
          @click:append-outer="
            () => {
              handleDownload({
                filename: renderFileBaseName,
                allColumns: false,
                withHeader: false,
              });
            }
          "
          suffix=".xlsx"
          v-model="renderFileBaseName"
          style="text-align: right"
        />
        <!-- <v-btn
          small
          icon
          @click="$store.dispatch('app/home/rowUndo', selectedRow)"
        >
          <v-icon>mdi-undo</v-icon>
        </v-btn>        
        <v-btn
          small
          icon
          @click="$store.dispatch('app/home/rowRedo', selectedRow)"
        >
          <v-icon>mdi-redo</v-icon>
        </v-btn> -->
      </template>
      <template v-slot:header-r>
        <!-- <span class="mr-4">
          <v-icon small class="mb-1">mdi-form-textbox</v-icon>
          {{ maxLength }}
        </span> -->

        <v-btn
          v-tooltip="'Enable Text Search'"
          small
          icon
          @click="$store.dispatch('app/config/toggleEnableFilters')"
          class="mr-2"
        >
          <v-icon v-if="$store.getters['app/config/enableFilters']"
            >mdi-text-box-search-outline</v-icon
          >
          <v-icon v-else>mdi-text-search</v-icon>
        </v-btn>

        <v-btn
          v-tooltip="'Remove starting digits from rows.'"
          color="red"
          small
          icon
          @click="handleRemoveStartingDigits"
          class="mr-2"
        >
          <v-icon>mdi-counter</v-icon>
        </v-btn>

        <span class="mr-2 noselect" v-tooltip="'Total Rows'">
          <v-icon small class="mb-1">mdi-format-list-bulleted</v-icon>
          {{ $store.getters["app/home/rowCount"] }}
        </span>
      </template>
    </vue-editable-grid>
    <spell-check v-if="$store.getters['app/config/enableSpellCheck']" />
    <!-- .replace(/[0-9]/g, '') -->

    <!--
    <div>
      <v-btn
        small
        icon
        @click="$store.dispatch('app/home/rowUndo', selectedRow)"
      >
        <v-icon>mdi-undo</v-icon>
      </v-btn>
      <v-btn icon class="primary" small dark>{{ selectedRow.sn }}</v-btn>
      <v-btn
        small
        icon
        @click="$store.dispatch('app/home/rowRedo', selectedRow)"
      >
        <v-icon>mdi-redo</v-icon>
      </v-btn>
    </div>
    -->
    <ImportXL
      :hasHeader="false"
      :open="showUploader"
      @sheetImported="sheetImported"
      focusable="false"
    />
  </v-col>
</template>

<script>
import { mapGetters } from "vuex";
import log from "@/log";
import bus from "@/bus";

import ImportXL from "./ImportXL";
import CtxMenu from "./CtxMenu";
import SheetExport from "./SheetExport";
import SheetImported from "./SheetImported";
import CanRender from "../CanRender";
import moment from "@/plugins/moment";
import SpellCheck from "../../modules/SpellCheck";
export default {
  name: "data-editor",
  components: {
    ImportXL,
    SpellCheck,
  },

  data() {
    return {
      showUploader: null,
      isAppendImport: false,
      grid: null,
      fontSizee: "30px",
    };
  },
  computed: {
    ...mapGetters({
      maxLength: "app/config/maxLength",
      selectOptions: "app/home/selectOptions",
      activeColumn: "app/config/activeColumn",
      serialColumn: "app/config/serialColumn",
      lengthColumn: "app/config/lengthColumn",
      linesColumn: "app/config/linesColumn",
      highlightColor: "app/config/highlightColor",
      fontSize: "app/config/fontSize",
    }),

    gridStyle() {
      let mStyle = `font-size: ${this.fontSize}px;`;
      if (this.$store.getters["app/config/enableSpellCheck"]) {
        mStyle += `height: calc(100vh - 6rem);`;
      } else {
        mStyle += `height: calc(100vh - 4rem);`;
      }
      return mStyle;
    },
    CanRender: CanRender,
    textColumnIndex() {
      return this.columnDefs.findIndex((el) => el.field == "text");
    },
    columnDefs() {
      let defs = [];
      if (this.activeColumn) {
        defs.push({
          sortable: false,
          filter: false,
          field: "active",
          headerName: "🔘",
          type: "text",
          editable: false,
          className: "grid-active",
          size: "50px",
        });
      }

      if (this.serialColumn) {
        defs.push({
          sortable: false,
          filter: false,
          field: "sn",
          headerName: "#",
          type: "numeric",
          editable: false,
          className: "grid-sn",
          size: "50px",
        });
      }

      defs.push({
        sortable: false,
        filter: true,
        field: "text",
        headerName: "Slug Text",
        editable: true,
        // maxlength: 300,
        className: "grid-text",
        size: "1fr",
      });

      if (this.lengthColumn) {
        defs.push({
          sortable: false,
          filter: false,
          field: "length",
          headerName: "⟷",
          type: "numeric",
          editable: false,
          className: "grid-length",
          size: "50px",
        });
      }

      if (this.linesColumn) {
        defs.push({
          sortable: false,
          filter: false,
          field: "lines",
          headerName: "📑",
          type: "numeric",
          editable: false,
          className: "grid-lines",
          size: "50px",
        });
      }

      return defs;
    },
    renderFileBaseName: {
      get() {
        return this.$store.getters["app/config/renderFileBaseName"];
      },
      set(val) {
        return this.$store.dispatch("app/config/setRenderFileBaseName", val);
      },
    },
    selectedRow: {
      get() {
        return this.$store.getters["app/home/selectedRow"];
      },
      set(row) {
        return this.$store.dispatch("app/home/setSelectedRow", row);
      },
    },

    rows: {
      get() {
        return this.$store.getters["app/home/rows"];
      },
      set(data) {
        return this.$store.dispatch("app/home/setRows", data).then(() => {
          this.formatRows();
        });
      },
    },
  },
  watch: {
    selectedRow(val) {},
    activeColumn(val) {},
    maxLength(val) {
      this.$store.dispatch("app/home/resetSN", { formatRows: this.formatRows });
    },
    rows() {
      this.$store.dispatch("app/home/resetSN", { formatRows: this.formatRows });
    },
    highlightColor(val) {
      this.$store.dispatch("app/home/resetSN", { formatRows: this.formatRows });
    },
    fontSize(val) {
      this.$store.dispatch("app/home/resetSN", { formatRows: this.formatRows });
    },
  },
  mounted() {
    this.grid = this.$refs.grid;
    // document.getElementById("cell" + this.selectedRow.sn - 1 + "-2").onkeyup =
    //     (e) => {
    //       log.info("e", e);
    //     };

    bus.$on("selectRow", (sn) => {
      let idx = sn - 1;
      this.selectRow(idx);
    });
    bus.$on("scrollToRow", (sn) => {
      let idx = sn - 1;
      this.scrollToIndex(idx);
    });
    bus.$on(
      "download",
      ({ filename = null, allColumns = false, withHeader = false } = {}) => {
        this.handleDownload({ filename, allColumns, withHeader });
      }
    );
    bus.$on("importFile", ({ append }) => {
      this.showUploader = !this.showUploader;
      this.isAppendImport = append;
    });

    bus.$on("config/highlightColor", () => {
      //this.$store.dispatch("app/home/resetSN", { formatRows: this.formatRows });
    });

    bus.$on("reset", () => {
      this.$store.dispatch("app/home/clearRows", {
        formatRows: this.formatRows,
      });
    });
    setTimeout(() => {
      this.formatRows();
    }, 1000);
  },
  methods: {
    handleRemoveStartingDigits() {
      if (
        confirm("Are you sure to remove all starting digits from rows?") != true
      ) {
        return;
      }
      if (
        confirm(
          "Are you really sure to remove all starting digits from rows?"
        ) != true
      ) {
        return;
      }
      this.$store.dispatch("app/home/removeStartingNumbersFromRows");
    },
    scrollToIndex(idx) {
      let rowHeight = this.fontSize * 2;
      let offset = rowHeight * idx;
      let tableDiv = document.querySelector("table.grid-table tbody");
      tableDiv.scrollTop = offset - screen.height / 3;
      // tableDiv.scrollTop = offset - rowHeight * 4;
    },
    scrollToRow(row) {
      let idx = row.sn ? row.sn - 1 : 0;
      this.scrollToIndex(idx);
    },
    selectRow(rowIdx = 0) {
      this.grid
        // .selectCell(rowIdx, 2)
        .selectCell(rowIdx, this.textColumnIndex)
        .then((rr) => {
          this.grid.focus();
        })
        .catch((err) => {
          // log.error("errrrrr", err);
        });
    },
    handleDownload({
      filename = null,
      allColumns = false,
      withHeader = false,
    }) {
      if (!filename) {
        filename = this.renderFileBaseName;
      }
      if (!allColumns) {
        filename = filename; //+ "-single-column";
      } else {
        filename = filename + "-all-columns";
      }

      filename = filename + "-" + moment().format("YYYYMMDD-HHmm");

      SheetExport({
        filename,
        allColumns,
        withHeader,
      });
    },
    formatRows() {
      this.rows.forEach((row) => {
        if (row && row.text && row.text.length > 0) {
        }
        this.formatRow(row);
      });
    },
    getHighlightColors(row) {
      let bgColor = null;
      let textColor = "#000";

      if (this.CanRender && row.lines && row.lines > 2) {
        bgColor = "#ffcccc";
      } else if (row.text == "-") {
        bgColor = "#ddddff";
      }

      if (row.text && row.text.length > this.maxLength) {
        bgColor = bgColor ? bgColor : this.highlightColor;
      }

      return { bgColor, textColor };
    },
    formatRow(row) {
      if (!row || !row.sn) {
        return;
      }
      const { bgColor, textColor } = this.getHighlightColors(row);

      let elmId = "cell" + (parseInt(row.sn) - 1) + "-" + this.textColumnIndex;
      let elm = document.getElementById(elmId);

      row.$cellStyle = {
        text: { backgroundColor: bgColor, color: textColor },
      };
      if (elm) {
        elm.style.backgroundColor = bgColor;
        elm.style.color = textColor;
      }
    },
    onContextMenu(evt) {
      CtxMenu(evt);
    },
    onKeyUp(e) {
      if (e && e.evt && e.evt.input) {
        this.selectedRow.text = e.evt.input.value;
        this.selectedRow.length = e.evt.input.value.trim().length;
      }
    },
    rowSelected(e) {
      // if editing bottom row, will add an extra row at bottom to
      // jump to next row when cellupdated
      if (e.rowIndex > this.rows.length - 2) {
        let emptyRows = this.rows.filter(
          (el) => !el.text || el.text.trim().length < 1
        );
        if (emptyRows.length < 2) {
          this.$store.dispatch("app/home/addRow");
        }
      }

      //toggle active if colIndex is 0

      if (e.colIndex == 0) {
        this.$store.dispatch("app/home/toggleActive", e.rowData);
      }

      this.selectedRow = {
        ...e.rowData,
      };

      setTimeout(() => {
        this.formatRow(e.rowData);
      }, 80);
    },

    sheetImported(sheet) {
      SheetImported({
        sheet,
        isAppendImport: this.isAppendImport,
        formatRows: this.formatRows,
      });
    },

    cellUpdated(e) {
      this.$store.dispatch("app/home/cellUpdated", {
        row: e.row,
        formatRow: this.formatRow,
        gridRef: this.grid,
      });
      //this.scrollToIndex(e.rowIndex);
    },
  },
  beforeCreate() {
    // this is to prevent saving column size.
    // comment blow line if you want to preseve column width over reloads
    localStorage.removeItem("grid_columns");
  },
  beforeDestroy() {
    bus.$off("selectRow");
    bus.$off("scrollToRow");
    bus.$off("download");
    bus.$off("importFile");
    bus.$off("config/highlightColor");
    bus.$off("reset");
  },
};
</script>
<style>
.grid {
  /* height: calc(100vh - 6rem); */
  width: 100%;
  margin-bottom: 0px;
  padding-bottom: 0px;
  border-bottom: 1px solid #ccc;
  scroll-behavior: smooth;
}
.grid thead,
.grid .grid-tools {
  font-size: 1.1rem;
}
.grid .headers-row {
  background-color: ghostwhite;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                    supported by Chrome, Edge, Opera and Firefox */
}

.ctxmenu {
  font-family: "Roboto";
  position: fixed;
  background: ghostwhite;
  color: rgb(60, 0, 128);
  cursor: pointer;
  border: 1px rgb(60, 0, 128) solid;
  border-radius: 0.3rem;
  width: 200px;
  font-size: 0.9rem;
  z-index: 1000;
}

.ctxmenu > p {
  padding: 0.2rem 0.5rem;
  margin: 5px 0;
  transition-duration: 0.3s;
  padding-left: 0.5rem;
  border-radius: 0.1rem;
}
.ctxmenu .subheader {
  padding: 10px;
  color: #999;
  background-color: #eee;
  border-radius: 0.3rem;
}
.ctxmenu > p:hover {
  background: rgb(60, 0, 128);
  color: ghostwhite;
  padding-left: 0.2rem;
}
.ctxmenu > p.separator {
  border-bottom: 1px solid #ccc;
  margin: 8px 5px;
  padding: 0;
}
.border-right {
  border-right: 1px solid grey;
}

.cell {
  font-family: "Mukta Regular", sans-serif;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cell-content {
  color: red;
}

.cell-content span {
  color: black;
}
.headers-row {
  border-top: 1px solid rgb(200, 200, 200);
}
.filters-row {
  background-color: rgb(224, 224, 224);
}

.resize-handle {
  border-right: 1px solid rgb(200, 200, 200);
}
.input-100px input {
  text-align: right;
  width: 230px;
}
</style>