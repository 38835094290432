import XLSX from "xlsx";
import log from "@/log";
import store from "@/store";

export default ({ allColumns = false, withHeader = false } = {}) => {
  let rows = store.getters["app/home/rows"];
  let data = rows.filter((el) => el.text && el.text.trim());

  data = data.map((el) => {
    el.text = el.text.trim();

    if (store.getters["isLocked"]) {
      console.log("Something went wrong...");
      return { text: "" };
    } else {
      return allColumns
        ? {
            active: el.active,
            sn: el.sn,
            text: el.text,
            length: el.length,
          }
        : { text: el.text };
    }
  });

  if (data.length < 1) {
    log.warn("Sheet is empty");
    return;
  }

  var ws = XLSX.utils.json_to_sheet(data, {
    skipHeader: withHeader ? 0 : 1,
  });
  var workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, ws, "Sheet1");
  return workbook;
};
