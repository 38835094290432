<template>
  <v-snackbar
    absolute
    app
    centered
    v-model="snackbar"
    content-class="noselect text-center justify-center"
  >
    Made with ❤️ in India.
    <!-- <template v-slot:action="{ attrs }">
          <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
            Close
          </v-btn>
        </template> -->
  </v-snackbar>
</template>

<script>
import bus from "@/bus";
export default {
  data: () => ({
    snackbar: null,
  }),
  created() {
    bus.$on("show-about-snackbar", () => {
      this.snackbar = true;
    });
  },
  beforeDestroy() {
    bus.$off("show-about-snackbar");
  },
};
</script>

<style>
</style>