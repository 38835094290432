const selectOptions = [
  { value: "»", text: "» Arrow" },
  { value: "❌", text: "✖ OFF" },
  { value: "✔️", text: "✔ ON" },
  { value: "🎬", text: "🎬 Rendered" },
  { value: "👉", text: "👉 This" },
  { value: "📌", text: "📌 Pin" },
  { value: "🚩", text: "🚩 Flag" },
  { value: "🌟", text: "🌟 Star" },
];
export default {
  selectOptions,
  selectedRow: {},
  loopRows: false,
  renderStatus: null,
  rows: [
    {
      active: selectOptions[0].value,
      sn: 1,
      text: "-",
      length: 0,
      history: [""],
    },
  ],
};
