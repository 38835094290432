import bus from "@/bus";
import log from "@/log";
import store from "@/store";

export default ({ error = () => {}, success = () => {} } = {}) => {
  store
    .dispatch("app/auth/fetchMe")
    .then((data) => {
      success(data);
    })
    .catch((err) => {
      // log.error("fetchMe", err);
      error(err);
    });
};
