import localforage from "@/plugins/localforage";
import vuetify from "@/plugins/vuetify";
import store from "@/store";
import router from "@/router";
import bus from "@/bus";

// fetch supported version
store.dispatch("fetchSupportedVersion");

// persisting theme
localforage.getItem("isThemeDark").then((val) => {
  store.dispatch("setThemeDark", val);
  vuetify.framework.theme.dark = val;
});

// persisting rows
localforage.getItem("rows").then((val) => {
  store.dispatch("app/home/setRows", val).then(() => {
    if (val && val.length > 0) {
      store.dispatch("app/home/setSelectedRow", val[0]);
    }
  });
});

// persisting config/maxLength
localforage.getItem("config/maxLength").then((val) => {
  store.dispatch("app/config/setMaxLength", val);
});

// persisting config/maxEmptyRows
localforage.getItem("config/maxEmptyRows").then((val) => {
  store.dispatch("app/config/setMaxEmptyRows", val);
});

// persisting config/fontSize
localforage.getItem("config/fontSize").then((val) => {
  store.dispatch("app/config/setFontSize", val);
});

// persisting config/activeColumn
localforage.getItem("config/activeColumn").then((val) => {
  store.dispatch("app/config/setActiveColumn", val);
});

// persisting config/lengthColumn
localforage.getItem("config/lengthColumn").then((val) => {
  if (val == null) {
    val = true;
  }
  store.dispatch("app/config/setLengthColumn", val);
});

// persisting config/linesColumn
localforage.getItem("config/linesColumn").then((val) => {
  if (val == null) {
    val = false;
  }
  store.dispatch("app/config/setLinesColumn", val);
});

// persisting config/highlightColor
localforage.getItem("config/highlightColor").then((val) => {
  store.dispatch("app/config/setHighlightColor", val);
});

// persisting config/renderFileBaseName
localforage.getItem("config/renderFileBaseName").then((val) => {
  store.dispatch("app/config/setRenderFileBaseName", val);
});

// persisting config/renderBaseDirectory
localforage.getItem("config/renderBaseDirectory").then((val) => {
  store.dispatch("app/config/setRenderBaseDirectory", val);
});

// persisting config/playInterval
localforage.getItem("config/playInterval").then((val) => {
  store.dispatch("app/config/setPlayInterval", val);
});

// persisting config/renderFontSize
localforage.getItem("config/renderFontSize").then((val) => {
  store.dispatch("app/config/setRenderFontSize", val);
});

// persisting config/renderLineHeight
localforage.getItem("config/renderLineHeight").then((val) => {
  store.dispatch("app/config/setRenderLineHeight", val);
});

// persisting config/renderServerURL
localforage.getItem("config/renderServerURL").then((val) => {
  store.dispatch("app/config/setRenderServerURL", val);
});

// persisting config/renderBatch
localforage.getItem("config/renderBatch").then((val) => {
  store.dispatch("app/config/setRenderBatch", val);
});

// persisting config/enableSpellCheck
localforage.getItem("config/enableSpellCheck").then((val) => {
  store.dispatch("app/config/setEnableSpellCheck", val);
});

// persisting config/breakLine
localforage.getItem("config/breakLine").then((val) => {
  store.dispatch("app/config/setBreakLine", val);
});

// persisting config/breakLineWordLimit
localforage.getItem("config/breakLineWordLimit").then((val) => {
  store.dispatch("app/config/setBreakLineWordLimit", val);
});

// persisting config/breakEllipsis
localforage.getItem("config/breakEllipsis").then((val) => {
  store.dispatch("app/config/setBreakEllipsis", val);
});

// persisting config/slugBandOffset
localforage.getItem("config/slugBandOffset").then((valXY) => {
  console.log("valXY", valXY);
  if (!valXY) {
    valXY = { x: 0, y: 0 };
  }
  store.dispatch("app/config/setSlugBandOffset", valXY);
});

// // persisting authentication
// store
//   .dispatch("auth/setToken")
//   .then(() => {
//     store
//       .dispatch("auth/fetchUser")
//       .then((res) => {})
//       .catch((err) => {
//         if (err == "NOT_FOUND") {
//           store.dispatch("auth/clearAuth");
//           router.replace({ name: "login" });
//         }
//       });
//   })
//   .catch((err) => {
//     // no auth token
//     store.dispatch("auth/clearAuth");
//   });
