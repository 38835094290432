import log from "@/log";
import store from "@/store";
import moment from "@/plugins/moment";

import fs from "@/../electron/client-plugins/fs";
import { remote } from "@/../electron/client-plugins/electron";
const { dialog } = remote;
export default ({ filename, data, dontAskForDestination }) => {
  if (dontAskForDestination) {
    const path = store.getters["app/config/renderBaseDirectory"];
    fs.writeFileSync(
      path + moment().format("YYYY-MMM-DD") + "/" + filename,
      data
    );
    alert("saved at: " + path);
  } else {
    dialog
      .showOpenDialog({
        properties: ["openDirectory"],
      })
      .then((result) => {
        if (!result.canceled) {
          log.info("outputPath", result.filePaths[0]);
          const path = result.filePaths[0].replace(/\\/g, "/") + "/";
          fs.writeFileSync(path + filename, data);
          log.info("saved at:", path + filename);
          alert("saved at: " + path + filename);
        }
      });
  }
};
