import log from "@/log";
import bus from "@/bus";
import store from "@/store";

const roles = ["admin", "superadmin", "manager", "moderator", "mkb"];
export default () => {
  const authData = store.getters["app/auth/authData"];
  if (!authData) {
    return false;
  }
  if (!roles.includes(authData.role)) {
    return false;
  }
  return true;
};
