export const SET_CURRENT_ROUTE = (state, route) => {
  state.currRoute = route;
};
export const SET_CURR_LOCALE = (state, locale) => {
  state.currLocale = locale;
};
export const SET_THEME_DARK = (state, trueOrFalse) => {
  state.isThemeDark = trueOrFalse;
};

export const RESET_STATE = (state) => {
  state.currRoute = null;
  state.isThemeDark = false;
  state.alert = { type: null, message: null, icon: null };
};

export const SET_ALERT = (
  state,
  { type, message, icon = null, translate = false }
) => {
  state.alert = {
    type: type,
    message: message,
    icon: icon,
    translate: translate,
  };
};

export const SET_FORM_ERRORS = (state, { errors }) => {
  state.formErrors = errors;
};

export const SET_SUPPORTED_VERSION = (state, version) => {
  state.supportedVersion = version;
  state.isSupportedVersionFeatched = true;
};

export const SET_LOCKED = (state, trueFalse) => {
  state.isLocked = trueFalse;
  state.isLockedRenderLimit = trueFalse;
};
