<template>
  <v-app>
    <fullscreen-wait />
    <alert-bar />
    <!-- <offline-status /> -->
    <!-- <v-scroll-y-transition>
    </v-scroll-y-transition> -->
    <v-system-bar
      class="black d-flex justify-center"
      dark
      app
      fixed
      v-if="!this.$online && false"
    >
      Not connected to the internet, Working offline.
    </v-system-bar>
    <v-main>
      <router-view />
      <about-snackbar />
      <!-- <privacy-modal /> -->
    </v-main>
  </v-app>
</template>

<script>
import AboutSnackbar from "@/components/AboutSnackbar";
import FullscreenWait from "./components/FullscreenWait";
import AlertBar from "./components/AlertBar";
// import OfflineStatus from "./components/OfflineStatus";
// import PrivacyModal from "./components/PrivacyModal";
import AuthCheck from "@/components/AuthCheck";
import { axios } from "@/plugins/axios";

export default {
  name: "App",
  components: {
    AboutSnackbar,
    FullscreenWait,
    AlertBar,
    // PrivacyModal,
    // OfflineStatus,
  },
  data: () => ({}),
  mounted() {
    AuthCheck({
      error: (err) => {
        this.$store.dispatch("setAlert", {
          message:
            "Due to security reasons, You have been logged out. Please login again.",
          type: "red white--text",
        });
        this.$router.replace({ name: "logout" });
      },
    });
  },
  methods: {},
};
</script>
<style>
::-webkit-scrollbar {
  width: 0px;
}
.grid ::-webkit-scrollbar {
  width: 6px;
}
.grid ::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.grid ::-webkit-scrollbar-thumb {
  background: rgb(0, 36, 112);
  border-radius: 6px;
}
.grid ::-webkit-scrollbar-thumb:hover {
  background: rgb(1, 48, 201);
  border-radius: 10px;
}

/* disable text selection */
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                    supported by Chrome, Edge, Opera and Firefox */
}

.v-card__text,
.v-card__title {
  word-break: normal !important;
}

.tooltip {
  font-family: "Roboto";
  display: block !important;
  z-index: 10000;
  font-size: 0.8rem;
}

.tooltip .tooltip-inner {
  background: rgb(61, 61, 61);
  color: white;
  border-radius: 16px;
  padding: 5px 10px 4px;
}

.tooltip .tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
  border-color: rgb(61, 61, 61);
  z-index: 1;
}

.tooltip[x-placement^="top"] {
  margin-bottom: 5px;
}

.tooltip[x-placement^="top"] .tooltip-arrow {
  border-width: 5px 5px 0 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="bottom"] {
  margin-top: 5px;
}

.tooltip[x-placement^="bottom"] .tooltip-arrow {
  border-width: 0 5px 5px 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  top: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="right"] {
  margin-left: 5px;
}

.tooltip[x-placement^="right"] .tooltip-arrow {
  border-width: 5px 5px 5px 0;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  left: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip[x-placement^="left"] {
  margin-right: 5px;
}

.tooltip[x-placement^="left"] .tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  right: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip[aria-hidden="true"] {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.15s, visibility 0.15s;
}

.tooltip[aria-hidden="false"] {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.15s;
}
</style>
