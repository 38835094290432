export const getCurrentRoute = (state) => {
  return state.currRoute;
};

export const getAppVersion = (state) => {
  return state.appVersion;
};

export const getCurrLocale = (state) => {
  return state.currLocale;
};

export const isThemeDark = (state) => {
  return state.isThemeDark;
};

export const getThemeAccentColor = (state) => {
  return state.isThemeDark ? "blue" : "yellow";
};

export const getAlert = (state) => {
  return state.alert;
};

export const getFormErrors = (state) => {
  return state.formErrors;
};

export const supportedVersion = (state) => {
  return state.supportedVersion;
};
export const isSupportedVersionFeatched = (state) => {
  return state.isSupportedVersionFeatched;
};

export const isLocked = (state) => {
  return state.isLocked;
};

export const isLockedRenderLimit = (state) => {
  return state.isLockedRenderLimit;
};

export const isSupportedVersion = (state) => {
  const appVersion = 10;
  if (state.supportedVersion == null) {
    return false;
  } else if (state.supportedVersion == -1) {
    return true;
  } else if (appVersion >= state.supportedVersion) {
    return true;
  } else {
    return false;
  }
};
