import bus from "@/bus";
import log from "@/log";
import store from "@/store";
import { padZero } from "@/helpers/utils";
import moment from "@/plugins/moment";
import axios from "axios";
export default (data) => {
  return axios
    .post(store.getters["app/config/renderServerURL"] + "upload", data)
    .then((res) => {
      log.info(res.data.fullpath);
      return res.data;
    })
    .catch((err) => {
      log.error(err.response);
      return err.response;
    });

  // return ipcRenderer
  //   .invoke("frame-render", data)
  //   .then((res) => {
  //     log.info("rendered as:", res.fullpath);
  //     return res;
  //   })
  //   .catch((err) => {
  //     log.info("render error", err);
  //   });
};
