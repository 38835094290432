import { locales as auth } from "./auth";
import { locales as home } from "./home";
import { locales as config } from "./config";
import { locales as errors } from "./errors";

export default {
  en: {
    ...auth.en,
    ...home.en,
    ...config.en,
    ...errors.en,
  },
  hi: {
    ...auth.hi,
    ...home.hi,
    ...config.hi,
    ...errors.hi,
  },
};
