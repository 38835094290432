import { store as auth } from "./auth";
import { store as home } from "./home";
import { store as config } from "./config";

const state = () => ({});
const getters = {};
const actions = {};
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    auth,
    home,
    config,
  },
};
