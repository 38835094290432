export default {
  "Please Wait": "Please wait...",
  "ROOT:FULLSCREEN_WAIT:ALERT_MSG-TEXT|LOGOUT": "Logging you out...",

  "ROOT:DRAWER:TOOLBAR_GUEST_TITLE-TEXT": "Welcome!",
  "ROOT:DRAWER:BUTTON_HOME-TEXT": "Home",
  "ROOT:DRAWER:BUTTON_GROUPS-TEXT": "Groups",
  "ROOT:DRAWER:BUTTON_PROFILE-TEXT": "Profile",
  "ROOT:DRAWER:BUTTON_UPDATE_PROFILE-TEXT": "Update Profile",
  "ROOT:DRAWER:BUTTON_UPDATE_EMAIL-TEXT": "Update Email",
  "ROOT:DRAWER:BUTTON_UPDATE_PASSWORD-TEXT": "Change Password",
  "ROOT:DRAWER:BUTTON_LOGIN-TEXT": "Login",
  "ROOT:DRAWER:BUTTON_LOGOUT-TEXT": "Logout",
  "ROOT:DRAWER:BUTTON_REGISTER-TEXT": "Register",

  VERIFYSTATUSTEXT: "Click here to verify your account.",

  APP_TITLE: "Excelite",
  INR: "₹ %s/-",
  MENU: "Menu",
  ACCOUNT: "Account",
  SETTINGS: "Settings",
  USER_PREFS: "User Preferences",
  GENERAL: "General",
  DARK_THEME: "Dark Theme",
  LIGHT_THEME: "Light Theme",
  ON: "On",
  OFF: "Off",
  SOMETHING_WENT_WRONG: "Something went wrong!",
  NO_MORE_ITEMS: "No more items.",
  NOT_CONNECTED: "You are not connected to Internet.",

  Login: "Login",
  Logout: "Logout",
  Register: "Register",
  Update_Email: "Update Email",
  Update_Password: "Update Password",
  Update_Profile: "Update Profile",

  HOME: "Home",
  GROUP: "Group",
  GROUPS: "Groups",
  TEAMS: "Teams",
  TEAM: "Team",
  MEMBER: "Member",
  MEMBERS: "Membrs",
  TOTAL: "Total",
  SUBTOTAL: "Subtotal",

  CONFIRM_DELETE: "Are you sure to delete <b style='color:red'>%s</b>?",
  YES: "Yes",
  NO: "No",
  OK: "OK",
  CANCEL: "Cancel",
  REMOVE: "Remove",
  UPDATE: "Update",
  CREATE: "Create",
  NEW: "New",
  NEXT: "Next",
  PREVIOUS: "Previous",
  PAGE: "Page",
  DESCRIPTION: "Description",
  SUBJECT: "Subject",
  "CORRECT-OPTION": "Correct Option",
  OPTIONA: "Option (A)",
  OPTIONB: "Option (B)",
  OPTIONC: "Option (C)",
  OPTIOND: "Option (D)",
  TITLE: "Title",
  EXAMS: "Exams",
  EXAM: "Exam",
  QUESTIONS: "Questions",
  QUESTION: "Question",
  QSTN: "Qs.",
  TAGS: "Tags",
  TAG: "Tag",
  SUBJECTS: "Subjects",
  SUBJECT: "Subject",
  NOTES: "Notes",
  NOTE: "Note",
  MOCKTESTS: "Mock Tests",
  MOCKTEST: "Mock Test",
  TESTS: "Tests",
  TEST: "Test",
  SEGMENTS: "Segments",
  SEGMENT: "Segment",
  DURATION: "Duration",
  EXPLANATION: "Explanation",
  NOITEMS: "No items in here",
  SEGMENTED: "Use segmented time duration?",
  ATTEMPT: "Attempt",
  START: "Start",
  RESUME: "Resume",
  "TOTAL-QUESTIONS": "Total Questions",
  MARKANDNEXT: "Mark & Next",
  UNMARK: "Unmark",
  CLEAR: "Clear",
  SUBMIT: "Submit",
  SAVEANDNEW: "Save & New",
  ACCEPTANDSTART: "Accept and Start",
  CONFIRMLEAVE: "Test has not completed yet, Leave without complition?",
  USERS: "Users",
  AUTH: "Auth",
  PREPARE: "Prepare",
  LOADING: "Loading, Please wait...",
  SCORE: "Score",
  SPEED: "Speed",
  ACCURACY: "Accuracy",
  CORRECT: "Correct",
  INCORRECT: "Incorrect",
  ATTEMPTED: "Attempted",
  SKIPPED: "Skipped",
  ANSWER: "Answer",
  MARKEDFORREVIEW: "Marked for Review",
  RANK: "RANK",
  "I Accept": "Accept",
  Decline: "Decline",
  DATE: "Date",
  LEARN: "Learn",
  REPORT: "Report",
  MYATTEMPTS: "My Attempts",
  CONTINUEATTEMPTS: "Continue Attempts...",
  COMINGSOON: "Coming soon! Stay tuned.",
  HELP: "Help",
  AUTH_REQUIRED: "Please Sign Up or Login to continue...",
  BACK: "Back",
  EXIT: "Exit",
  LOGIN: "Login",
  SIGNUP: "Sign Up",
  CREATEACCOUNT: "Create Account",
  MOBILENUMBER: "Mobile Number",
  UPVOTE: "Up Vote",
  DOWNVOTE: "Down Vote",
  ROTATELEFT: "Rotate Left",
  ROTATERIGHT: "Rotate Right",
  FLIPVERTICAL: "Flip Vertical",
  FLIPHORIZONTAL: "Flip Horizontal",
  CROP: "Crop",
  CHOOSEANIMAGE: "Choose an Image",
  DONE: "Done",
  UPLOAD: "Upload",
  RESET: "Reset",
  RESTART: "Restart",
};
