import log from "@/log";
import store from "@/store";
export default ({ sheet, isAppendImport, formatRows }) => {
  if (sheet.length < 1) {
    return;
  }
  if (sheet[0][2] == "Text" || sheet[0][2] == "text") {
    if (
      !sheet[0][0] ||
      !sheet[0][1] ||
      !sheet[0][3] ||
      sheet[0][0] != "active" ||
      sheet[0][1] != "sn" ||
      sheet[0][3] != "length"
    ) {
      log.info("invalid format");
      return;
    }
    // has text at third column
    //skip first row
    log.info("has text at third column, skip first row");
    sheet = sheet.slice(1, sheet.length);
    sheet = sheet.filter((el) => el[2] && el[2].trim().length > 0);

    sheet = sheet.map((row, idx) => {
      return {
        active: row[0]
          ? row[0].trim()
          : store.getters["app/home/selectOptions"][0].value,
        sn: row[1] ? parseInt(row[1]) : idx + 1,
        text: row[2].trim(),
        length: row[2] ? row[2].length : 0,
      };
    });
  } else {
    log.info("use first column, do not skip first row");
    sheet = sheet.filter((el) => el[0] && el[0].trim().length > 0);

    sheet = sheet.map((row, idx) => {
      return {
        active: store.getters["app/home/selectOptions"][0].value,
        sn: idx + 1,
        text: row[0].trim(),
        length: row[0] ? row[0].length : 0,
      };
    });
  }

  if (isAppendImport) {
    let rows = store.getters["app/home/rows"];
    rows.map((el) => log.info("rows", el.text));
    rows.pop(); // <-- removes last element from array
    rows.push(...sheet);
    store.dispatch("app/home/setRows", rows);
  } else {
    store.dispatch("app/home/setRows", sheet);
  }
  store.dispatch("app/home/addRow");

  setTimeout(() => {
    store.dispatch("app/home/resetSN", { formatRows });
  }, 100);
};
