<template>
  <v-container
    class="fill-height d-flex justify-center text-center align-center"
  >
    <v-card width="500">
      <form @submit.prevent="submit">
        <v-card-text>
          <v-text-field
            v-model="email"
            :error-messages="emailErrors"
            label="E-mail"
            required
            @input="$v.email.$touch()"
            @blur="$v.email.$touch()"
          ></v-text-field>

          <v-text-field
            v-model="password"
            :error-messages="passwordErrors"
            :counter="10"
            label="Password"
            type="password"
            required
            @input="$v.password.$touch()"
            @blur="$v.password.$touch()"
          ></v-text-field>

          <!-- <v-checkbox
            v-model="checkbox"
            :error-messages="checkboxErrors"
            label="Do you agree?"
            required
            @change="$v.checkbox.$touch()"
            @blur="$v.checkbox.$touch()"
          ></v-checkbox> -->
        </v-card-text>

        <v-card-actions>
          <v-btn
            type="submit"
            :loading="loading"
            :disabled="loading"
            class="primary mr-4"
            outlined
            dark
            block
          >
            Login
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </form>
    </v-card>
  </v-container>
</template>
<script>
import { validationMixin } from "vuelidate";
import { required, maxLength, email } from "vuelidate/lib/validators";

import { mapGetters, mapActions } from "vuex";
export default {
  mixins: [validationMixin],
  validations: {
    password: { required, maxLength: maxLength(50) },
    email: { required, email },
    checkbox: {
      checked(val) {
        return val;
      },
    },
  },
  components: {},
  data() {
    return {
      email: "mkb@excelite.com",
      password: "",
      checkbox: true,
      loading: false,
    };
  },
  computed: {
    ...mapGetters("app/auth", {
      loginStatus: "getLoginStatus",
    }),
    checkboxErrors() {
      const errors = [];
      if (!this.$v.checkbox.$dirty) return errors;
      !this.$v.checkbox.checked && errors.push("You must agree to continue!");
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.maxLength &&
        errors.push("Password must be at most 50 characters long");
      !this.$v.password.required && errors.push("Name is required.");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Must be valid e-mail");
      !this.$v.email.required && errors.push("E-mail is required");
      return errors;
    },
  },
  methods: {
    ...mapActions("app/auth", {
      loginUser: "loginUser",
    }),
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        // do your submit logic here
        this.login();
      }
    },
    clear() {
      this.$v.$reset();
      this.password = "";
      this.email = "";
      this.checkbox = false;
    },
    async login() {
      if (this.email && this.password) {
        // this.loading = true;
        await this.loginUser({ email: this.email, password: this.password });

        if (this.loginStatus === "success") {
          this.$router.replace(
            this.$route.redirectedFrom &&
              this.$route.redirectedFrom.name != "logout"
              ? this.$route.redirectedFrom
              : { name: "home" }
          );
        } else {
          // this.loading = false;
          alert("falied to login");
        }
      }
    },
  },
};
</script>
<style>
</style>