export const getRandomColor = () => {
  var letters = "0123456789ABCDEF";
  var color = "#";
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const colorLuminance = (hex, lum) => {
  // validate hex string
  hex = String(hex).replace(/[^0-9a-f]/gi, "");
  if (hex.length < 6) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  lum = lum || 0;

  // convert to decimal and change luminosity
  var rgb = "#",
    c,
    i;
  for (i = 0; i < 3; i++) {
    c = parseInt(hex.substr(i * 2, 2), 16);
    c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
    rgb += ("00" + c).substr(c.length);
  }

  return rgb;
};

export const getRandomColorLuminance = (lum) => {
  return colorLuminance(getRandomColor(), lum);
};

export const scrollToTargetAdjusted = (element, headerOffset = 100) => {
  var elementPosition = element.getBoundingClientRect().top;
  var offsetPosition = elementPosition - headerOffset;

  window.scrollTo({
    top: offsetPosition,
    behavior: "smooth",
  });
};

export const validateURL = (value) => {
  return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
    value
  );
};

// alternate expression
//^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+
export const validateYouTubeUrl = (url) => {
  if (url) {
    var regExp = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    if (url.match(regExp)) {
      return true;
    }
  }
  return false;
};
export const youTubeIdFromLink = (url) =>
  url.match(
    /(?:https?:\/\/)?(?:www\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\/?\?v=|\/embed\/|\/)([^\s&]+)/
  )[1];

export const copyToClipboard = (str) => {
  const el = document.createElement("textarea");
  el.value = str;
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
};

export const areObjectsEqual = (...objects) =>
  objects.every((obj) => JSON.stringify(obj) === JSON.stringify(objects[0]));

export const stripScriptTags = (s) => {
  var div = document.createElement("div");
  div.innerHTML = s;
  var scripts = div.getElementsByTagName("script");
  var i = scripts.length;
  while (i--) {
    scripts[i].parentNode.removeChild(scripts[i]);
  }
  return div.innerHTML;
};

export const flattenArray = (arr) => {
  return arr.reduce(function(flat, toFlatten) {
    return flat.concat(
      Array.isArray(toFlatten) ? flattenArray(toFlatten) : toFlatten
    );
  }, []);
};

export const shuffleArray = (array) => {
  var currentIndex = array.length,
    temporaryValue,
    randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
};

export const toDataURL = (url) => {
  return fetch(url)
    .then((response) => {
      return response.blob();
    })
    .then((blob) => {
      return URL.createObjectURL(blob);
    });
};

export const downloadDataURL = async (dataURL, title) => {
  title = title && convertToSlug(title) ? convertToSlug(title) : "myImage";
  const a = document.createElement("a");
  a.href = dataURL;
  a.download = title + ".png";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const downloadURL = async (url, title) => {
  const dataURL = await toDataURL(url);
  downloadDataURL(dataURL, title);
};
export const convertToSlug = (Text) => {
  return Text.toLowerCase()
    .replace(/[^\w ]+/g, "")
    .replace(/ +/g, "-");
};

export const padZero = (num, places) => String(num).padStart(places, "0");

export const getRandomString = (length) => {
  var randomChars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var result = "";
  for (var i = 0; i < length; i++) {
    result += randomChars.charAt(
      Math.floor(Math.random() * randomChars.length)
    );
  }
  return result;
};

export const elementResizeWatcher = (element, callback) => {
  var resolve = function(element) {
      return typeof element === "string"
        ? document[
            [".", "#"].indexOf(element.charAt(0)) < 0
              ? "getElementById"
              : "querySelector"
          ](element)
        : element;
    },
    observer,
    watched = [],
    checkForElementChanges = function(data) {
      var w = data.el.offsetWidth,
        h = data.el.offsetHeight;
      if (data.offsetWidth !== w || data.offsetHeight !== h) {
        data.offsetWidth = w;
        data.offsetHeight = h;
        data.cb({
          target: data.el,
          width: w,
          height: h,
        });
      }
    },
    checkForChanges = function() {
      watched.forEach(checkForElementChanges);
    },
    started = false,
    self = {
      start: function() {
        if (!started) {
          // Listen to the window resize event
          window.addEventListener("resize", checkForChanges);

          // Listen to the element being checked for width and height changes
          observer = new MutationObserver(checkForChanges);
          observer.observe(document.body, {
            attributes: true,
            childList: true,
            characterData: true,
            subtree: true,
          });

          started = true;
        }
      },
      stop: function() {
        if (started) {
          window.removeEventListener("resize", checkForChanges);
          observer.disconnect();
          started = false;
        }
      },
      addListener: function(element, callback) {
        if (typeof callback !== "function") return;

        var el = resolve(element);
        if (typeof el === "object") {
          watched.push({
            el: el,
            offsetWidth: el.offsetWidth,
            offsetHeight: el.offsetHeight,
            cb: callback,
          });
        }
      },

      removeListener: function(element, callback) {
        var el = resolve(element);
        watched = watched.filter(function(data) {
          return !(data.el === el && data.cb === callback);
        });
      },
    };

  self.addListener(element, callback);

  self.start();

  return self;
};
