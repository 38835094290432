import { render, staticRenderFns } from "./DataRenderCanvas.vue?vue&type=template&id=32b3f543&scoped=true&"
import script from "./DataRenderCanvas.vue?vue&type=script&lang=js&"
export * from "./DataRenderCanvas.vue?vue&type=script&lang=js&"
import style0 from "./DataRenderCanvas.vue?vue&type=style&index=0&id=32b3f543&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32b3f543",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
installComponents(component, {VBtn,VCard,VCardActions,VSheet,VSpacer,VSubheader})
