import Vue from "vue";
import Router from "vue-router";
import beforeEach from "./beforeEach";
import { routes as appRoutes } from "@/app/index";
import patchRouterMethod from "./patchRouterMethod";

Vue.use(Router);

const router = new Router({
  mode: process.env.IS_ELECTRON ? "hash" : "history", //"history",
  base: `/`,
  scrollBehavior: () => ({ y: 0 }),
  routes: appRoutes,
});

router.beforeEach(beforeEach);

// solves
// NavigationDuplicated Navigating to current location <...> is not allowed
// based on : https://stackoverflow.com/a/63263736/1126340
patchRouterMethod(router, "push");
patchRouterMethod(router, "replace");

export default router;
