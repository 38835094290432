let electron = null;

if (process.env.IS_ELECTRON) {
  electron = require("electron");
} else {
  electron = {
    ipcRenderer: () => {},
    remote: {
      dialog: {
        showOpenDialog: () => {
          return new Promise((resolve, reject) => {
            return resolve({ canceled: true });
          });
        },
      },
    },
    shell: {
      openPath: () => {},
    },
  };
}

export const ipcRenderer = electron.ipcRenderer;
export const remote = electron.remote;
export const shell = electron.shell;

// import {
//   ipcRenderer as _ipcRenderer,
//   remote as _remote,
//   shell as _shell,
// } from "electron";

// export const ipcRenderer = _ipcRenderer;
// export const remote = _remote;
// export const shell = _shell;

// export const ipcRenderer = () => {};
// export const remote = () => {
//   dialog = () => {};
// };
// export const shell = () => {};
