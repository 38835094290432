import Vue from "vue";
import VTooltip from "v-tooltip";

Vue.use(VTooltip);

VTooltip.options.defaultTrigger = "click hover focus";
VTooltip.options.defaultTemplate = `
  <div class="tooltip" role="tooltip">
    <div class="tooltip-arrow"></div>
    <div class="tooltip-inner"></div>
  </div>`;

export default VTooltip;
