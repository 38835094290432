import Vue from "vue";
import moment from "moment";

Vue.filter("formatDate", function(value) {
  if (value) {
    return moment(String(value)).format("DD/MMM/YYYY hh:mm");
  }
});
Vue.filter("formatDateOnly", function(value) {
  if (value) {
    return moment(String(value)).format("DD MMM YYYY");
  }
});

Vue.filter("isSameDate", function(value) {
  if (value) {
    var value = moment(value, "YYYY-MM-DD hh:mm:ss").add(1, "d");
    var today = moment().format("YYYY-MM-DD hh:mm:ss");
    return moment(value).isSameOrAfter(today);
  }
});

Vue.filter("fromNow", function(value) {
  if (value) {
    return moment(String(value)).fromNow();
  }
});

export default moment;
