export default {
  maxLength: 80,
  maxEmptyRows: 500,
  activeColumn: false,
  serialColumn: true,
  lengthColumn: true,
  linesColumn: false,
  highlightColor: "#ffee00",
  fontSize: 20,
  playInterval: 200,
  fontName: "Mukta SemiBold",
  renderFontName: "Mukta ExtraBold",
  renderFileBaseName: "mkb-",
  renderBaseDirectory: "",
  renderTextUpperCase: true,
  renderFontSize: 48,
  renderLineHeight: 1.2,
  renderServerURL: "http://localhost:3132",
  renderBatch: true,
  isServerRunning: false,
  isRendererOK: false,
  tab2Column: false,
  enableSpellCheck: false,
  enableFilters: false,
  breakLine: false,
  breakLineWordLimit: 100,
  breakLineEllipsis: "...",
  slugBandOffset: { x: 0, y: 0 },
};
