export const rows = (state) => {
  return state.rows;
};
export const rowsWithData = (state) => {
  return state.rows.filter((el) => el.text && el.text.trim().length > 0);
};

export const renderStatus = (state) => {
  return state.renderStatus;
};

export const largeRows = (state) => {
  return state.rows.filter(
    (el) => el.length > 0 && (el.lines < 1 || el.lines > 2)
  );
};

export const rowCount = (state) => {
  return state.rows.filter((el) => el.text && el.text.trim().length).length;
};

export const selectedRow = (state) => {
  const row =
    state.selectedRow && state.selectedRow.sn
      ? state.selectedRow
      : {
          index: -1,
          sn: 0,
          text: "",
          length: 0,
          lines: -1,
        };
  // row.text = row.text ? row.text.toUpperCase() : "";
  // return row;
  if (row.sn < 1) {
    return row;
  }
  return state.rows.find((el) => el.sn == row.sn);
};

export const selectOptions = (state) => {
  return state.selectOptions;
};

export const loopRows = (state) => {
  return state.loopRows;
};


