import { jwtDecrypt } from "@/helpers/jwtDecrypt";
// import log from "@/log";

export default {
  saveAuthToken(state, payload) {
    let newAuthData = {
      token: null,
      refreshToken: null,
      tokenExp: null,
      tokenIat: null,
      uid: null,
      role: null,
    };
    if (payload && payload.token) {
      const jwtDecodeUserInfo = jwtDecrypt(payload.token);
      newAuthData = {
        token: payload.token,
        refreshToken: payload.refreshToken,
        tokenExp: jwtDecodeUserInfo.exp,
        tokenIat: jwtDecodeUserInfo.iat,
        uid: jwtDecodeUserInfo.uid,
        role: jwtDecodeUserInfo.data ? jwtDecodeUserInfo.data.role : null,
      };
    }
    state.authData = newAuthData;
  },
  saveLoginStatus(state, status) {
    state.loginStatus = status;
  },
  saveMe(state, data) {
    state.me = data;
  },
};
