import log from "@/log";
export const resetState = ({ commit }) => {
  commit("RESET_STATE");
};

export const setMaxLength = ({ commit }, length) => {
  commit("SET_MAX_LENGTH", length);
};
export const setMaxEmptyRows = ({ commit }, count) => {
  commit("SET_MAX_EMPTY_ROWS", count);
};

export const toggleActiveColumn = ({ commit }) => {
  commit("TOGGLE_ACTIVE_COLUMN");
};

export const setActiveColumn = ({ commit }, trueFalse) => {
  commit("SET_ACTIVE_COLUMN", trueFalse);
};

export const toggleLengthColumn = ({ commit }) => {
  commit("TOGGLE_LENGTH_COLUMN");
};

export const setLengthColumn = ({ commit }, trueFalse) => {
  commit("SET_LENGTH_COLUMN", trueFalse);
};

export const toggleLinesColumn = ({ commit }) => {
  commit("TOGGLE_LINES_COLUMN");
};

export const setLinesColumn = ({ commit }, trueFalse) => {
  commit("SET_LINES_COLUMN", trueFalse);
};

export const toggleSerialColumn = ({ commit }) => {
  commit("TOGGLE_SERIAL_COLUMN");
};

export const setSerialColumn = ({ commit }, trueFalse) => {
  commit("SET_SERIAL_COLUMN", trueFalse);
};

export const setHighlightColor = ({ commit }, color) => {
  commit("SET_HIGHLIGHT_COLOR", color);
};

export const setFontSize = ({ commit }, size) => {
  commit("SET_FONT_SIZE", size);
};

export const setPlayInterval = ({ commit }, duration) => {
  commit("SET_PLAY_INTERVAL", duration);
};
export const setRenderFileBaseName = ({ commit }, basename) => {
  commit("SET_RENDER_FILE_BASE_NAME", basename);
};

export const setRenderBaseDirectory = ({ commit }, baseDir) => {
  commit("SET_RENDER_BASE_DIRECTORY", baseDir);
};

export const setRenderFontSize = ({ commit }, size) => {
  commit("SET_RENDER_FONT_SIZE", size);
};

export const setRenderLineHeight = ({ commit }, height) => {
  commit("SET_RENDER_LINE_HEIGHT", height);
};

export const setRenderServerURL = ({ commit }, url) => {
  commit("SET_RENDER_SERVER_URL", url);
};

export const setRenderBatch = ({ commit }, trueFalse) => {
  commit("SET_RENDER_BATCH", trueFalse);
};

export const toggleRenderBatch = ({ commit }) => {
  commit("TOGGLE_RENDER_BATCH");
};

export const checkServerRunning = ({ commit }) => {
  commit("CHECK_SERVER_RUNNING");
};

export const toggleTab2Column = ({ commit }) => {
  commit("TOGGLE_TAB_2_COLUMN");
};

export const setEnableSpellCheck = ({ commit }, val) => {
  commit("SET_ENABLE_SPELL_CHECK", val);
};

export const toggleEnableSpellCheck = ({ commit }) => {
  commit("TOGGLE_ENABLE_SPELL_CHECK");
};

export const setEnableFilters = ({ commit }, val) => {
  commit("SET_ENABLE_FILTERS", val);
};

export const toggleEnableFilters = ({ commit }) => {
  commit("TOGGLE_ENABLE_FILTERS");
};

export const setBreakLine = ({ commit }, val) => {
  commit("SET_BREAK_LINE", val);
};

export const toggleBreakLine = ({ commit }) => {
  commit("TOGGLE_BREAK_LINE");
};

export const setBreakLineWordLimit = ({ commit }, val) => {
  commit("SET_BREAK_LINE_WORD_LIMIT", val);
};

export const setBreakEllipsis = ({ commit }, val) => {
  commit("SET_BREAK_LINE_ELLIPSIS", val);
};

export const setSlugBandOffset = ({ commit }, valXY) => {
  commit("SET_SLUG_BAND_OFFSET", valXY);
};
