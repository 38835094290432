import log from "@/log";
import localforage from "@/plugins/localforage";
import store from "@/store";
import { makeHistory, undo, redo } from "./HistoryManager";

export const RESET_STATE = (state) => {
  state = {};
  localforage.removeItem("rows");
  localforage.removeItem("selectedRow");
};

export const SET_ROW_CUSTOM_FONT_SIZE = (state, { row, customFontSize }) => {
  row = state.rows.find((el) => el.sn == row.sn);
  row.customFontSize = customFontSize;
  return localforage.setItem("rows", state.rows);
};

export const RESET_ROWS_CUSTOM_FONT_SIZE = (state) => {
  state.rows = state.rows.map((el) => {
    el.customFontSize = 46;
  });
  return localforage.setItem("rows", state.rows);
};

export const ROW_UNDO = (state, row) => {
  row = state.rows.find((el) => el.sn == row.sn);
  undo(row);
  return localforage.setItem("rows", state.rows);
};

export const ROW_REDO = (state, row) => {
  row = state.rows.find((el) => el.sn == row.sn);
  redo(row);
  return localforage.setItem("rows", state.rows);
};

export const REMOVE_ROW = (state, row) => {
  state.rows = state.rows.filter((el) => el.sn != row.sn);
  return localforage.setItem("rows", state.rows);
};

export const RESET_SN = (state) => {
  if (!state.rows || state.rows.length < 1) {
    return;
  }
  for (let i = 0; i < state.rows.length; i++) {
    state.rows[i].sn = i + 1;
    state.rows[i].length = state.rows[i].text.length;
  }
  return localforage.setItem("rows", state.rows);
};

export const SET_ROWS = (state, data) => {
  if (!data || data.length < 1) {
    data = [
      {
        active: state.selectOptions[0].value,
        sn: "1",
        text: "",
        length: 0,
        lines: -1,
        history: [],
        customFontSize: null,
      },
    ];
  }

  //add history
  data = data.map((el) => {
    let mHistory = makeHistory(el);
    el.history = mHistory.history;
    el.historyPointer = mHistory.historyPointer;
    return el;
  });

  return localforage.setItem("rows", data).then((values) => {
    state.rows = values;
    REMOVE_EMPTY_ROWS(state);
    ADD_ROW(state);
    setTimeout(() => {
      SET_SELECTED_ROW(state, state.rows[0]);
    }, 100);
  });
};

export const SET_SELECTED_ROW = (state, row) => {
  const oldRow = state.selectedRow;
  if (oldRow && oldRow.sn == row.sn && oldRow.text == row.text) {
    return;
  }

  //add history
  // let mHistory = makeHistory(row);
  // row.history = mHistory.history;
  // row.historyPointer = mHistory.historyPointer;

  return localforage.setItem("selectedRow", row).then((value) => {
    return (state.selectedRow = row);
  });
};

export const NEXT_ROW = (state, { hasNext = (trueFalse) => {} } = {}) => {
  const rows = state.rows.filter((el) => el.text && el.text.trim().length > 0);
  if (!rows || rows.length < 1) {
    return;
  }
  const selectedRow = state.selectedRow;
  let targetIndex = -1;

  if (!selectedRow || !selectedRow.sn || !selectedRow.text) {
    targetIndex = 0;
  }
  const selectedRowIndex = rows.findIndex((el) => el.sn == selectedRow.sn);
  targetIndex = selectedRowIndex + 1;

  if (targetIndex >= rows.length) {
    if (state.loopRows) {
      hasNext(true);
      targetIndex = 0;
    } else {
      hasNext(false);
      targetIndex = rows.length - 1;
    }
  } else {
    hasNext(true);
  }

  SET_SELECTED_ROW(state, rows[targetIndex]);
};

export const PREVIOUS_ROW = (state) => {
  const rows = state.rows.filter((el) => el.text && el.text.trim().length > 0);
  if (!rows || rows.length < 1) {
    return;
  }
  const selectedRow = state.selectedRow;
  let targetIndex = -1;

  if (!selectedRow || !selectedRow.sn || !selectedRow.text) {
    targetIndex = 0;
  } else {
    const selectedRowIndex = rows.findIndex((el) => el.sn == selectedRow.sn);
    targetIndex = selectedRowIndex - 1;
  }

  if (targetIndex < 0) {
    if (state.loopRows) {
      targetIndex = rows.length - 1;
    } else {
      targetIndex = 0;
    }
  }

  SET_SELECTED_ROW(state, rows[targetIndex]);
};

export const SELECT_FIRST_ROW = (state) => {
  SET_SELECTED_ROW(state, state.rows[0]);
};

export const SET_RENDER_STATUS = (state, status) => {
  state.renderStatus = status;
};

export const REMOVE_EMPTY_ROWS = (state) => {
  if (!state.rows || state.rows.length < 1) return;
  state.rows = state.rows.filter((el) => el.text && el.text.trim().length > 0);
  return localforage.setItem("rows", state.rows);
};
export const PATCH_ROW = (state, row) => {
  state.rows.map((el) => {
    if (el.sn == row.sn) {
      el.$cellStyle = row.$cellStyle;
      el.active = row.active;
      el.sn = row.sn ? row.sn : 1;
      el.text = row.text;
      el.lines = row.lines;
      el.length = row.text ? row.text.length : 0;
      el.customFontSize = row.customFontSize ? row.customFontSize : null;
      //add history
      let mHistory = makeHistory(el);
      el.history = mHistory.history;
      el.historyPointer = mHistory.historyPointer;
      
    }
    return el;
  });
  return localforage.setItem("rows", state.rows);
};

export const SET_ROW_LINES = (state, { sn, lines }) => {
  let persist = false;
  state.rows.map((el) => {
    if (el.sn == sn) {
      persist = el.lines != lines ? true : false;
      el.lines = lines;
    }
    return el;
  });
  // only update localstorage if there is line change
  if (persist) {
    return localforage.setItem("rows", state.rows);
  }
};

export const ADD_ROW = (state, { atIndex = null } = {}) => {
  const maxEmptyRows = store.getters["app/config/maxEmptyRows"];
  let item = {
    active: state.selectOptions[0].value,
    sn: "",
    text: "",
    length: 0,
    lines: -1,
    history: [],
    customFontSize: null,
  };

  if (atIndex !== null) {
    item.text = "-";
    state.rows.splice(atIndex, 0, item);
    return;
  } else {
    for (let i = 1; i <= maxEmptyRows; i++) {
      item.sn = state.rows.length + 1;
      state.rows.push({ ...item });
    }
  }
  return localforage.setItem("rows", state.rows);
};

export const TOGGLE_LOOP_ROWS = (state) => {
  state.loopRows = !state.loopRows;
  return localforage.setItem("home/loopRows", state.loopRows);
};

export const SET_LOOP_ROWS = (state, trueFalse) => {
  state.loopRows = trueFalse;
  return localforage.setItem("home/loopRows", state.loopRows);
};

export const REMOVE_STARTING_NUMBERS_FROM_ROWS = (state) => {
  let data = state.rows.map((el) => {
    el.text = el.text.replace(/(^\d+)./g, "");

    el.text = el.text
      .replace("………", "...")
      .replace("……", "...")
      .replace("…", "...")
      .replace("........", "...")
      .replace(".......", "...")
      .replace("......", "...")
      .replace(".....", "...")
      .replace("....", "...")
      .replace("... ...", "...")
      .replace("", "");
      

    el.text = el.text
      .replace("………", "...")
      .replace("……", "...")
      .replace("…", "...")
      .replace("........", "...")
      .replace(".......", "...")
      .replace("......", "...")
      .replace(".....", "...")
      .replace("....", "...")
      .replace("... ...", "...")
      .replace("", "");
      

    // remove spaces before period
    // el.text = el.text.replace(/\s+(\W)/g, "$1")

    return el;
  });

  data = state.rows.filter((el) => {
    return el.text != "-";
  });
  
  return SET_ROWS(state, data)

};