export default () => {
  return {
    authData: {
      token: "",
      refreshToken: "",
      tokenExp: "",
      uid: "",
      role: "",
    },
    loginStatus: "",
  };
};
