import XLSX from "xlsx";
import log from "@/log";
import moment from "@/plugins/moment";
import GenerateWorkbook from "./GenerateWorkbook";
import SheetExportElectron from "./SheetExportElectron";
import store from "@/store";
export default ({
  filename = null,
  allColumns = false,
  withHeader = false,
  dontAskForDestination = null,
} = {}) => {
  filename = filename ? filename : "export";
  if (dontAskForDestination) {
    filename = filename + "-" + moment().format("YYYYMMDD-HHmm");
  }
  filename = filename + ".xlsx";

  const workbook = GenerateWorkbook({
    allColumns,
    withHeader,
  });

  if (!process.env.IS_ELECTRON) {
    XLSX.writeFile(workbook, filename);
  } else {
    SheetExportElectron({
      filename: filename,
      data: XLSX.write(workbook, { type: "buffer" }),
      dontAskForDestination,
    });
  }
};
