// remove empty elements from an array
Array.prototype.clean = function(deleteValue) {
  for (var i = 0; i < this.length; i++) {
    if (this[i] == deleteValue) {
      this.splice(i, 1);
      i--;
    }
  }
  return this;
};

if (typeof Array.prototype.move === "undefined") {
  Array.prototype.move = function(from, to, on = 1) {
    return this.splice(to, 0, ...this.splice(from, on)), this;
  };
}

// Length of a JavaScript object
Object.size = function(obj) {
  var size = 0,
    key;
  for (key in obj) {
    if (obj.hasOwnProperty.call(key)) size++;
  }
  return size;
};

// upper case first letter for the string
String.prototype.ucFirst = function() {
  return this.charAt(0).toUpperCase() + this.substring(1).toLowerCase();
  //return this.charAt(0).toUpperCase() + this.slice(1);
};

String.prototype.ucWords = function() {
  return this.replace(/(^|\s)([a-z])/g, function(m, p1, p2) {
    return p1 + p2.toUpperCase();
  });
};

String.prototype.ucAll = function() {
  return this.toUpperCase();
};

String.prototype.stripTags = function() {
  return this.replace(/(<([^>]+)>)/gi, "");
};

String.prototype.fancyTime = function() {
  // Hours, minutes and seconds
  var hrs = ~~(this / 3600);
  var mins = ~~((this % 3600) / 60);
  var secs = ~~this % 60;

  // Output like "1:01" or "4:03:59" or "123:03:59"
  var ret = "";
  if (hrs > 0) {
    ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
  }
  ret += "" + mins + ":" + (secs < 10 ? "0" : "");
  ret += "" + secs;
  return ret;
};

String.prototype.convertToTime = function() {
  // Hours, minutes and seconds
  var hrs = ~~(this / 3600);
  var mins = ~~((this % 3600) / 60);
  var secs = ~~this % 60;

  // Output like "1:01" or "4:03:59" or "123:03:59"

  return {
    hours: hrs,
    minutes: mins,
    seconds: secs,
  };
};

String.prototype.ordinalSuffix = function() {
  let i = this;
  var j = i % 10,
    k = i % 100;

  if (j == 1 && k != 11) {
    ordinal = "st";
  } else if (j == 2 && k != 12) {
    ordinal = "nd";
  } else if (j == 3 && k != 13) {
    ordinal = "rd";
  } else {
    ordinal = "th";
  }

  return ordinal;
};

String.prototype.withOrdinalSuffix = function() {
  return this + this.ordinalSuffix();
};

String.prototype.replaceArray = function(find, replace) {
  var replaceString = this;
  var regex;
  for (var i = 0; i < find.length; i++) {
    regex = new RegExp(find[i], "g");
    replaceString = replaceString.replace(regex, replace[i]);
  }
  return replaceString;
};
