  <template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-tooltip="'Set output folder'"
          icon
          color="green darken-2"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-folder-open</v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="grey lighten-2">
          Output Folder
          <v-spacer></v-spacer>
          <v-btn v-tooltip="'Cancel'" icon @click.stop="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-actions>
          <v-text-field
            v-model="folderPath"
            append-outer-icon="mdi-content-save"
            @click:append-outer="setPath"
          ></v-text-field>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import log from "@/log";
import bus from "@/bus";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      dialog: false,
      folderPath: "",
    };
  },
  computed: {
    ...mapGetters({
      renderBaseDirectory: "app/config/renderBaseDirectory",
    }),
  },
  watch: {
    renderBaseDirectory() {
      this.folderPath = this.renderBaseDirectory;
    },
    dialog(val) {},
  },
  mounted() {
    this.folderPath = this.renderBaseDirectory;
  },
  methods: {
    setPath() {
      if (!this.folderPath || this.folderPath.trim().length < 1) {
        return;
      }
      this.$store.dispatch(
        "app/config/setRenderBaseDirectory",
        this.folderPath
      );
      this.$emit("selected", this.folderPath);
      this.dialog = false;
    },
  },
};
</script>

<style>
</style>