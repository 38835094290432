<template>
  <input
    type="file"
    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
    ref="fileSelector"
    @change="onChange"
    style="display: none"
    focusable="false"
  />
</template>
<script>
import XLSX from "xlsx";
import { getRandomString } from "@/helpers/utils";
import log from "@/log";
export default {
  components: {},
  props: ["open", "sheetName", "hasHeader"],
  data() {
    return {
      workbook: null,
      sheets: {},
      sheet: [],
      filename: "",
    };
  },
  watch: {
    open() {
      this.$refs.fileSelector.click();
    },
  },
  methods: {
    onChange(event) {
      this.fileReader(event);
    },
    onLoad(e) {
      var data = e.target.result;
      data = new Uint8Array(data);
      this.workbook = XLSX.read(data, { type: "array" });
      this.getSheet();
    },
    getSheet() {
      this.workbook.SheetNames.forEach((sheetName) => {
        var roa = XLSX.utils.sheet_to_json(this.workbook.Sheets[sheetName], {
          header: this.hasHeader ? 0 : 1, // first row is header if set to zero
        });
        if (roa.length) this.sheets[sheetName] = roa;
      });

      // see the sheets, caution: it works after reader event is done.
      this.sheet =
        this.sheets[
          this.sheetName ? this.sheetName : this.workbook.SheetNames[0]
        ];

      this.$emit("sheetImported", this.sheet);
      this.$store.dispatch(
        "app/config/setRenderFileBaseName",
        this.filename
          .split(".")
          .slice(0, -1)
          .join(".")
          .toLowerCase()
          .replace(/ /g, "-")
          .replace(/[^\w-]+/g, "")
          // + "-" +
          // getRandomString(4)
      );

      this.$refs.fileSelector.value = null;
    },
    fileReader(oEvent) {
      var oFile = oEvent.target.files[0];
      if (!oFile || !oFile.name) {
        log.info("No files have been selected");
        return;
      }
      if (
        !oFile ||
        !oFile.type ||
        oFile.type !=
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        log.info("Invalid File type");
        return;
      }

      // log.info(oFile);
      this.filename = oFile.name;

      var reader = new FileReader();
      reader.onload = this.onLoad;
      reader.readAsArrayBuffer(oFile);
    },
  },
};
</script>