import log from "@/log";
import Storage from "@/plugins/localforage";
import store from "@/store";
import { isEmpty } from "lodash";
import _axios from "axios";

export const axios = _axios.create({
  baseURL: "https://api.vikarn.com",
  headers: {
    "Content-Type": "application/json",
    Accept: "Token",
  },
  params: {
    locale: "check:plugins/axios/create",
  },
});

// set auth token if there is a token in localstorage
axios.interceptors.request.use(
  async function(config) {
    const token = await Storage.getItem("token");
    // const refreshToken = await Storage.get({ key: "refreshToken" });
    if (token) {
      config.headers.common["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  function(error) {
    log.error(error);
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function(successRes) {
    if (successRes.data && successRes.data.message) {
      store.dispatch(
        "apiAlert",
        {
          message: successRes.data.message,
          code: successRes.status,
          type: "info white--text",
        },
        { root: true }
      );
    }
    // clear form errors everytime a successful api request pass through
    store.dispatch("setFormErrors", { errors: [] }, { root: true });
    return successRes;
  },
  function(error) {
    if (error.response && error.response.data && error.response.data.message) {
      // if response has message
      // notify user
      store.dispatch(
        "apiAlert",
        {
          message: error.response.data.message,
          code: error.response.status,
          type: "red white--text",
        },
        { root: true }
      );
    }

    if (error.response && error.response.data && error.response.data.errors) {
      //if response has errors
      //notify user form
      store.dispatch(
        "setFormErrors",
        { errors: error.response.data.errors },
        { root: true }
      );
    } else {
      // clear form errors if there are no errors
      store.dispatch("setFormErrors", { errors: [] }, { root: true });
    }
    return Promise.reject(error);
  }
);
/*
 */

// set token after login success or remove if expired
// export const setHttpToken = (token) => {
//   return axios.interceptors.request.use(
//     function(config) {
//       if (isEmpty(token)) {
//         config.headers.common["Authorization"] = null;
//       } else {
//         config.headers.common["Authorization"] = "Bearer " + token;
//       }
//       return Promise.resolve(config);
//     },
//     function(error) {
//       log.error(error);
//       return Promise.reject(error);
//     }
//   );
// };
