<template>
  <v-toolbar
    elevation="0"
    dense
    height="33"
    class="noselect"
    style="z-index: 1"
  >
    <v-toolbar-title
      >{{ this.renderStatus ? this.renderStatus.ucWords() : "Preview" }}
    </v-toolbar-title>

    <button
      v-tooltip="'Current row number'"
      class="ma-0 pa-0"
      @click.stop="scrollToRow"
      :disabled="isProcessing"
    >
      <v-badge
        tile
        inline
        bordered
        :color="isPlaying ? 'green' : 'grey'"
        :content="selectedRow.sn"
      ></v-badge>
    </button>
    <v-btn
      icon
      x-small
      elevation="0"
      @click.stop="$store.dispatch('app/home/toggleLoopRows')"
      :color="$store.getters['app/home/loopRows'] ? 'green' : ''"
      :disabled="isProcessing"
    >
      <v-icon>mdi-sync</v-icon>
    </v-btn>
    <v-spacer></v-spacer>

    <v-btn
      v-tooltip="'Previous'"
      :disabled="loading || isProcessing"
      icon
      @click.stop="handlePrevious"
    >
      <v-icon>mdi-skip-previous</v-icon>
    </v-btn>

    <v-btn
      :disabled="
        loading || ['checking', 'rendering'].includes(this.renderStatus)
      "
      icon
      @click.stop="handleClickPlay"
    >
      <v-icon v-tooltip="'Play'" v-if="!isPlaying">mdi-play</v-icon>
      <v-icon v-tooltip="'Pause'" v-if="isPlaying">mdi-pause </v-icon>
    </v-btn>
    <v-btn
      v-tooltip="'Next'"
      :disabled="loading || isProcessing"
      icon
      @click.stop="handleNext"
    >
      <v-icon>mdi-skip-next</v-icon>
    </v-btn>

    <v-spacer></v-spacer>

    <v-btn
      v-tooltip="'Save Image'"
      icon
      @click.stop="handleClickSaveFrame"
      :loading="loading"
      :disabled="loading || isProcessing"
    >
      <v-icon>mdi-image-move</v-icon>
    </v-btn>
    <v-btn
      v-if="false && $store.getters['app/config/isRendererOK']"
      v-tooltip="'Render Selected'"
      icon
      :color="
        this.renderStatus == 'checking'
          ? 'orange'
          : this.renderStatus == 'rendering'
          ? 'red'
          : ''
      "
      @click.stop="handleClickSaveSelected"
      :loading="loading"
      :disabled="loading"
    >
      <v-icon>mdi-movie-open-star</v-icon>
    </v-btn>

    <v-btn
      v-if="$store.getters['app/config/isRendererOK']"
      v-tooltip="'Render All'"
      icon
      :color="
        this.renderStatus == 'checking'
          ? 'orange'
          : this.renderStatus == 'rendering'
          ? 'red'
          : ''
      "
      @click.stop="handleClickSaveAll"
      :loading="loading"
      :disabled="loading"
    >
      <v-icon>mdi-movie-open</v-icon>
    </v-btn>
  </v-toolbar>
</template>
<script>
import { mapGetters } from "vuex";
import log from "@/log";
import bus from "@/bus";
import axios from "axios";

export default {
  components: {},
  data() {
    return {
      loading: false,
      isPlaying: false,
      playIntervel: null,
    };
  },
  computed: {
    ...mapGetters({
      authData: "app/auth/authData",
      highlightColor: "app/config/highlightColor",
      fontSize: "app/config/fontSize",
      renderBatch: "app/config/renderBatch",
      selectedRow: "app/home/selectedRow",
      renderStatus: "app/home/renderStatus",
    }),

    renderFileBaseName: {
      get() {
        return this.$store.getters["app/config/renderFileBaseName"];
      },
      set(val) {
        return this.$store.dispatch("app/config/setRenderFileBaseName", val);
      },
    },

    isProcessing() {
      return (
        this.isPlaying || ["checking", "rendering"].includes(this.renderStatus)
      );
    },
  },
  updated() {
    this.$store.dispatch("app/config/checkServerRunning");
  },
  methods: {
    scrollToRow() {
      setTimeout(() => {
        bus.$emit("selectRow", this.selectedRow.sn);
        bus.$emit("scrollToRow", this.selectedRow.sn);
      }, this.$store.getters["app/config/playInterval"] / 2);
    },
    handleClickPlay() {
      this.isPlaying = !this.isPlaying;
      if (this.isPlaying) {
        this.playIntervel = setInterval(() => {
          this.$store.dispatch("app/home/nextRow", {
            hasNext: (trueFalse) => {
              if (!trueFalse) {
                this.isPlaying = false;
                clearInterval(this.playIntervel);
              }
            },
          });
          this.scrollToRow();
        }, this.$store.getters["app/config/playInterval"]);
      } else {
        clearInterval(this.playIntervel);
      }
    },
    handleNext() {
      this.$store.dispatch("app/home/nextRow");
      this.scrollToRow();
    },
    handlePrevious() {
      this.$store.dispatch("app/home/previousRow");
      this.scrollToRow();
    },
    handleClickSaveFrame() {
      this.loading = true;
      bus.$emit("renderSingle");
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    },

    handleClickSaveAll() {
      if (this.isProcessing) {
        this.loading = false;
        bus.$emit("renderCancel");
        return;
      }
      this.loading = true;
      bus.$emit("renderAll");
      setTimeout(() => {
        this.loading = false;
      }, 100);
    },

    handleClickSaveSelected() {
      if (this.isProcessing) {
        this.loading = false;
        bus.$emit("renderCancel");
        return;
      }
      this.loading = true;
      bus.$emit("renderSelected");
      setTimeout(() => {
        this.loading = false;
      }, 100);
    },
  },
};
</script>
<style >
</style>
