<template>
  <v-alert
    :color="$store.getters.getAlert.type"
    :icon="
      $store.getters.getAlert.icon ? $store.getters.getAlert.icon : 'mdi-alert'
    "
    transition="slide-y-transition"
    class="top-fixed-alert"
    v-if="$store.getters.getAlert.message"
  >
    {{
      $store.getters.getAlert.translate
        ? $lang($store.getters.getAlert.message)
        : $store.getters.getAlert.message
    }}
  </v-alert>
</template>

<script>
export default {
  name: "alert-bar",
};
</script>
<style scoped>
.top-fixed-alert {
  position: fixed;
  top: -4px;
  z-index: 10000;
  width: 100%;
}
</style>
