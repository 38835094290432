<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    persistent
    hide-overlay
    transition="dialog-bottom-transition"
    scrollable
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-tooltip="'Settings'" icon v-bind="attrs" v-on="on">
        <v-icon>mdi-cog</v-icon>
      </v-btn>
    </template>

    <div class="pt-14" style="background-color: #fff">
      <v-app-bar app color="deep-purple accent-4" dense dark>
        <v-toolbar-title>Settings</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="handleCancel">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-app-bar>
      <v-row no-gutters>
        <v-col cols="12" :md="CanRender ? 6 : 12">
          <v-card tile class="mx-4 mt-3">
            <v-card-title>Data Editor</v-card-title>
            <v-divider></v-divider>
            <v-card-text class="ma-0 pa-0">
              <v-list nav dense>
                <v-list-item-group color="primary">
                  <v-list-item
                    @click.stop="
                      () => {
                        activeColumn = !activeColumn;
                      }
                    "
                  >
                    <v-list-item-icon>
                      <v-checkbox
                        class="ma-0 pa-0"
                        :loading="loading"
                        v-model="activeColumn"
                        readonly
                      ></v-checkbox>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Active column</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item @click.stop="serialColumn = !serialColumn">
                    <v-list-item-icon>
                      <v-checkbox
                        class="ma-0 pa-0"
                        :loading="loading"
                        v-model="serialColumn"
                        readonly
                      ></v-checkbox>
                    </v-list-item-icon>
                    <v-list-item-title>Serial numbers</v-list-item-title>
                  </v-list-item>

                  <v-list-item @click.stop="lengthColumn = !lengthColumn">
                    <v-list-item-icon>
                      <v-checkbox
                        class="ma-0 pa-0"
                        :loading="loading"
                        v-model="lengthColumn"
                        readonly
                      ></v-checkbox>
                    </v-list-item-icon>
                    <v-list-item-title>Character count</v-list-item-title>
                  </v-list-item>

                  <v-list-item
                    @click.stop="enableSpellCheck = !enableSpellCheck"
                  >
                    <v-list-item-icon>
                      <v-checkbox
                        class="ma-0 pa-0"
                        :loading="loading"
                        v-model="enableSpellCheck"
                        readonly
                      ></v-checkbox>
                    </v-list-item-icon>
                    <v-list-item-title
                      >Enable Spell check (en-GB)</v-list-item-title
                    >
                  </v-list-item>

                  <v-list-item
                    v-if="false"
                    @click.stop="linesColumn = !linesColumn"
                  >
                    <v-list-item-icon>
                      <v-checkbox
                        class="ma-0 pa-0"
                        :loading="loading"
                        v-model="linesColumn"
                        readonly
                      ></v-checkbox>
                    </v-list-item-icon>
                    <v-list-item-title>GFX Slug Lines</v-list-item-title>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-action>
                      <v-text-field
                        :loading="loading"
                        v-model="maxLength"
                        type="number"
                        class="input-small ma-0 pa-0"
                      ></v-text-field>
                    </v-list-item-action>
                    <v-list-item-title>Max text length</v-list-item-title>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-action>
                      <v-text-field
                        :loading="loading"
                        v-model="fontSizeInternal"
                        type="number"
                        class="input-small ma-0 pa-0"
                      ></v-text-field>
                    </v-list-item-action>
                    <v-list-item-title>Font Size</v-list-item-title>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-action>
                      <v-text-field
                        :loading="loading"
                        v-model="maxEmptyRows"
                        type="number"
                        class="input-small ma-0 pa-0"
                      ></v-text-field>
                    </v-list-item-action>
                    <v-list-item-title>Max buffer empty rows</v-list-item-title>
                  </v-list-item>

                  <v-list-item @click.stop="breakLine = !breakLine" v-if="true">
                    <v-list-item-icon>
                      <v-checkbox
                        class="ma-0 pa-0"
                        :loading="loading"
                        v-model="breakLine"
                        readonly
                      ></v-checkbox>
                    </v-list-item-icon>
                    <v-list-item-title>Auto Break Line</v-list-item-title>
                  </v-list-item>

                  <v-list-item v-if="true">
                    <v-list-item-action>
                      <v-text-field
                        :disabled="!breakLine"
                        :loading="loading"
                        v-model="breakLineWordLimit"
                        type="number"
                        class="input-small ma-0 pa-0"
                      ></v-text-field>
                    </v-list-item-action>
                    <v-list-item-title>Break line word limit</v-list-item-title>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-action>
                      <pick-color ma-0 pa-0 />
                    </v-list-item-action>
                    <v-list-item-content
                      class="px-1"
                      :style="
                        'white-space: nowrap; overflow: hidden; text-overflow: ellipsis;background-color:' +
                        highlightColor
                      "
                    >
                      This is how the highlighted text length will look.
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="6" v-if="CanRender">
          <v-card tile class="mx-4 mt-3">
            <v-card-title>Data Renderer</v-card-title>
            <v-divider></v-divider>
            <v-card-text class="ma-0 pa-0">
              <v-list nav dense>
                <v-list-item-group color="primary">
                  <v-list-item
                    @click.stop="
                      () => {
                        renderBatch = !renderBatch;
                      }
                    "
                  >
                    <v-list-item-icon>
                      <v-checkbox
                        class="ma-0 pa-0"
                        :loading="loading"
                        v-model="renderBatch"
                        readonly
                      ></v-checkbox>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Batch Render</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>

                <v-list-item @click.stop="linesColumn = !linesColumn">
                  <v-list-item-icon>
                    <v-checkbox
                      class="ma-0 pa-0"
                      :loading="loading"
                      v-model="linesColumn"
                      readonly
                    ></v-checkbox>
                  </v-list-item-icon>
                  <v-list-item-title>GFX Slug Lines Count</v-list-item-title>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item v-if="false">
                  <v-list-item-action>
                    <v-chip label class="mb-6 mt-6">{{
                      renderFontSize
                    }}</v-chip>
                  </v-list-item-action>
                  <v-list-item-title>
                    <v-slider
                      class="mt-4 mb-0 pb-0"
                      v-model="renderFontSize"
                      :max="48"
                      :min="40"
                      thumb-color="red"
                    ></v-slider>
                    <div class="d-flex mt-n6 mx-2">
                      <span> Current font size: {{ renderFontSize }} </span>
                      <v-spacer></v-spacer>
                      <span> Default font size: 46 </span>
                    </div>
                  </v-list-item-title>
                </v-list-item>

                <v-list-item v-if="false">
                  <v-list-item-action>
                    <v-text-field
                      :loading="loading"
                      v-model="slugBandOffsetY"
                      type="number"
                      class="input-small ma-0 pa-0"
                    ></v-text-field>
                  </v-list-item-action>
                  <v-list-item-title>Slug Band Offset Y</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import log from "@/log";
import bus from "@/bus";
import PickColor from "./PickColor";
import CanRender from "@/app/home/components/CanRender";
export default {
  name: "editor-settings",
  props: ["show"],
  components: { PickColor },
  data() {
    return {
      dialog: true,
      loading: false,
      fontSizeInternal: 20,
    };
  },
  watch: {
    show(val) {
      this.dialog = val;
      this.loading = false;
    },
    fontSize(val) {
      this.fontSizeInternal = val;
    },
  },
  computed: {
    ...mapGetters({
      authData: "app/auth/authData",
      highlightColor: "app/config/highlightColor",
      fontSize: "app/config/fontSize",
    }),
    CanRender: CanRender,
    activeColumn: {
      get() {
        return this.$store.getters["app/config/activeColumn"];
      },
      set(val) {
        return this.$store.dispatch("app/config/toggleActiveColumn");
      },
    },
    lengthColumn: {
      get() {
        return this.$store.getters["app/config/lengthColumn"];
      },
      set(val) {
        return this.$store.dispatch("app/config/toggleLengthColumn");
      },
    },
    enableSpellCheck: {
      get() {
        return this.$store.getters["app/config/enableSpellCheck"];
      },
      set(val) {
        return this.$store.dispatch("app/config/toggleEnableSpellCheck");
      },
    },
    breakLine: {
      get() {
        return this.$store.getters["app/config/breakLine"];
      },
      set(val) {
        return this.$store.dispatch("app/config/toggleBreakLine");
      },
    },
    breakLineWordLimit: {
      get() {
        return this.$store.getters["app/config/breakLineWordLimit"];
      },
      set(val) {
        return this.$store.dispatch("app/config/setBreakLineWordLimit", val);
      },
    },
    serialColumn: {
      get() {
        return this.$store.getters["app/config/serialColumn"];
      },
      set(val) {
        return this.$store.dispatch("app/config/toggleSerialColumn");
      },
    },
    linesColumn: {
      get() {
        return this.$store.getters["app/config/linesColumn"];
      },
      set(val) {
        return this.$store.dispatch("app/config/toggleLinesColumn");
      },
    },
    slugBandOffsetY: {
      get() {
        return this.$store.getters["app/config/slugBandOffset"].y;
      },
      set(valY) {
        let valXY = { x: 0, y: valY };
        return this.$store.dispatch("app/config/setSlugBandOffset", valXY);
      },
    },
    maxLength: {
      get() {
        return this.$store.getters["app/config/maxLength"];
      },
      set(val) {
        return this.$store.dispatch("app/config/setMaxLength", val);
      },
    },
    maxEmptyRows: {
      get() {
        return this.$store.getters["app/config/maxEmptyRows"];
      },
      set(val) {
        return this.$store.dispatch("app/config/setMaxEmptyRows", val);
      },
    },
    renderBatch: {
      get() {
        return this.$store.getters["app/config/renderBatch"];
      },
      set(val) {
        return this.$store.dispatch("app/config/toggleRenderBatch");
      },
    },
    renderFontSize: {
      get() {
        return this.$store.getters["app/config/renderFontSize"];
      },
      set(val) {
        return this.$store.dispatch("app/config/setRenderFontSize", val);
      },
    },
  },
  methods: {
    handleSave() {
      this.dialog = false;
      this.$store.dispatch("app/config/setFontSize", this.fontSizeInternal);
      this.$emit("clicked", true);
    },
    handleCancel() {
      this.dialog = false;
      this.$store.dispatch("app/config/setFontSize", this.fontSizeInternal);
      this.$emit("clicked", false);
    },
  },
  created() {
    this.dialog = this.show;
    this.fontSizeInternal = this.fontSize;
  },
};
</script>
<style>
.input-small input {
  width: 40px;
}
.input-small input::-webkit-outer-spin-button,
.input-small input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.input-small input[type="number"] {
  -moz-appearance: textfield;
}
</style>