import bus from "@/bus";
import log from "@/log";
import store from "@/store";
import axios from "axios";
export default (data) => {
  return axios
    .post(store.getters["app/config/renderServerURL"] + "upload-xlsx", data)
    .then((res) => {
      log.info(res.data.fullpath);
      return res.data;
    })
    .catch((err) => {
      log.error(err.response);
      return err.response;
    });
};
