import log from "@/log";
import { makeHistory } from "./HistoryManager";
export const resetState = ({ commit }) => {
  commit("RESET_STATE");
};

export const cellUpdated = (
  { state, dispatch },
  { row, formatRow, gridRef }
) => {

  setTimeout(() => {
    // so that a row is never empty
    if (!row.text || row.text.trim().length < 1) {
      row.text = "-";
    }

    // remove spaces
    if (row.text && row.text.length > 0) {
      row.text = row.text
        .split(" ")
        .filter((s) => s)
        .join(" ");
    }
    dispatch("removeEmptyRows");
    dispatch("addRow");
    setTimeout(() => {
      formatRow(row);
    }, 100);

    //add history
    let mHistory = makeHistory(row);
    row.history = mHistory.history;
    row.historyPointer = mHistory.historyPointer;

    // scroll to bottom when added
    if (state.rows.length - 2 < row.sn) {
      setTimeout(() => {
        // #grid -> table -> tbody
        let tbody = gridRef.$el.children[1].children[0].children[1];
        tbody.scrollTop = tbody.scrollHeight + 200;
      }, 100);
    }
  }, 200);
};

export const resetSN = ({ commit }, { formatRows }) => {
  commit("RESET_SN");
  return formatRows();
};

export const patchRow = ({ commit }, row) => {
  return commit("PATCH_ROW", row);
};

export const rowUndo = ({ commit }, row) => {
  return commit("ROW_UNDO", row);
};
export const rowRedo = ({ commit }, row) => {
  return commit("ROW_REDO", row);
};

export const setRowLines = ({ commit }, { sn, lines }) => {
  return commit("SET_ROW_LINES", { sn, lines });
};

export const setSelectedRow = ({ commit }, row) => {
  return commit("SET_SELECTED_ROW", row);
};

export const nextRow = ({ commit }, { hasNext = (trueFalse) => {} } = {}) => {
  return commit("NEXT_ROW", { hasNext });
};
export const previousRow = ({ commit }) => {
  return commit("PREVIOUS_ROW");
};

export const toggleLoopRows = ({ commit }) => {
  return commit("TOGGLE_LOOP_ROWS");
};

export const setLoopRows = ({ commit }, trueFalse) => {
  return commit("SET_LOOP_ROWS", trueFalse);
};

export const removeEmptyRows = ({ commit }) => {
  return commit("REMOVE_EMPTY_ROWS");
};

export const clearRows = ({ dispatch }, { formatRows }) => {
  dispatch("setRows", []);
  dispatch("resetSN", { formatRows });
};

export const setRows = ({ commit, dispatch }, data) => {
  commit("SET_ROWS", data);
  return dispatch("removeEmptyRows");
};

export const selectFirstRow = ({ commit }) => {
  commit("SELECT_FIRST_ROW");
};

export const setRenderStatus = ({ commit }, status) => {
  commit("SET_RENDER_STATUS", status);
};


export const toggleActive = ({ state, commit, dispatch }, row) => {
  let idx = state.selectOptions.findIndex((el) => el.value == row.active);
  idx++;
  if (idx > state.selectOptions.length - 1) {
    idx = 0;
  }
  row.active = state.selectOptions[idx].value;
  return dispatch("patchRow", row);
};

export const setRowCustomFontSize = ({ commit }, {row, customFontSize}) => {
  commit("SET_ROW_CUSTOM_FONT_SIZE", { row, customFontSize });
};

export const resetRowsCustomFontSize = ({ commit }) => {
  commit("RESET_ROWS_CUSTOM_FONT_SIZE");
};



export const addRow = ({ commit }, { atIndex = null } = {}) => {
  commit("ADD_ROW", { atIndex });
};

export const removeRow = ({ commit }, row) => {
  commit("REMOVE_ROW", row);
};

export const removeStartingNumbersFromRows = ({ commit }) => {
  commit("REMOVE_STARTING_NUMBERS_FROM_ROWS");
};
export const resetRowHistory = ({ commit }) => {
  commit("RESET_ROW_HISTORY");
};
