import Storage from "@/plugins/localforage";
import { axios } from "@/plugins/axios";
import log from "@/log";

export default {
  async loadStorageTokens({ commit }) {
    const token = await Storage.getItem("token");
    const refreshToken = await Storage.getItem("refreshToken");
    if (token && refreshToken) {
      const tokenData = {
        token: token,
        refreshToken: refreshToken,
      };
      commit("saveAuthToken", tokenData);
    }
  },
  async logoutUser({ commit }) {
    await commit("saveAuthToken", null);
    await commit("saveLoginStatus", null);
    await Storage.removeItem("token");
    await Storage.removeItem("refreshToken");
  },

  async loginUser({ commit }, payload) {
    const response = await axios.post("auth/authorise", payload);

    if (response.status == 200 || response.status == 201) {
      await Storage.setItem("token", response.data.token);
      await Storage.setItem("refreshToken", response.data.refreshToken);

      commit("saveAuthToken", response.data);
      commit("saveLoginStatus", "success");
    } else {
      commit("saveLoginStatus", "failed");
    }
  },

  async fetchMe({ commit }) {
    return axios
      .get("auth/me")
      .then((res) => {
        commit("saveMe", res.data);
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        commit("saveMe", {});
        return Promise.reject(err.response);
      });
  },
};
