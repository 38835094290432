import log from "@/log";
import { ipcRenderer } from "@/../electron/client-plugins/electron";
export default (data) => {
  if (process.env.IS_ELECTRON) {
    return ipcRenderer
      .invoke("frame-render", data)
      .then((res) => {
        log.info("rendered as:", res.fullpath);
        return res;
      })
      .catch((err) => {
        log.info("render error", err);
      });
  }
};
