import "@/helpers/prototype";
import Vue from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import { locales } from "@/locales";
import "@/helpers/persist";
import vtooltip from "@/plugins/vtooltip";
import VueOnlineProp from "@/plugins/vue-online-prop.js";
import moment from "@/plugins/moment";
import vuetify from "./plugins/vuetify";
import VueEditableGrid from "@/plugins/vue-editable-grid";
import "./registerServiceWorker";

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  VueEditableGrid,
  locales,
  VueOnlineProp,
  moment,
  vtooltip,
  render: (h) => h(App),
}).$mount("#app");
