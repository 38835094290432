<template>
  <v-dialog
    v-model="dialog"
    max-width="350"
    hide-overlay
    transition="dialog-bottom-transition"
    scrollable
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on">
        <v-icon>mdi-format-color-fill</v-icon>
      </v-btn>
    </template>
    <v-card outlined class="px-0 pt-4">
      <v-card-text
        class="d-flex justify-center text-center fill-height align-center"
      >
        <v-color-picker
          dot-size="25"
          mode="hexa"
          swatches-max-height="200"
          v-model="bgColor"
          :swatches="swatches"
          :show-swatches="true"
        ></v-color-picker>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import log from "@/log";
import bus from "@/bus";

export default {
  name: "pick-color",
  props: ["show"],
  data() {
    return {
      dialog: true,
      loading: false,
      bgColor: "#ff0000",
      swatches: [
        ["#FF0000", "#AA0000"],
        ["#FFFF00", "#AAAA00"],
        ["#00FF00", "#00AA00"],
        ["#00FFFF", "#00AAAA"],
        ["#0000FF", "#0000AA"],
      ],
    };
  },
  watch: {
    show(val) {
      this.dialog = val;
      this.loading = false;
    },
    dialog(val) {
      if (!val) {
        this.$store.dispatch("app/config/setHighlightColor", this.bgColor);
        bus.$emit("config/highlightColor");
      }
    },
    highlightColor(val) {
      this.bgColor = val;
    },
  },
  computed: {
    ...mapGetters({
      authData: "app/auth/authData",
      highlightColor: "app/config/highlightColor",
    }),
  },
  methods: {},
  created() {
    this.dialog = this.show;
    this.bgColor = this.highlightColor;
  },
};
</script>

<style>
</style>