import log from "@/log";
import localforage from "@/plugins/localforage";
import axiosRaw from "axios";

export const RESET_STATE = (state) => {
  state = {};
  localforage.removeItem("config/maxLength");
  localforage.removeItem("config/activeColumn");
};

export const SET_MAX_LENGTH = (state, length) => {
  length = length && parseInt(length) > 1 ? parseInt(length) : 70;
  state.maxLength = length;
  return localforage.setItem("config/maxLength", state.maxLength);
};

export const SET_MAX_EMPTY_ROWS = (state, count) => {
  if (count == null) {
    count = 500;
  }
  if (count > 800) {
    count = 800;
  }
  state.maxEmptyRows = count;
  return localforage.setItem("config/maxEmptyRows", state.maxEmptyRows);
};

export const TOGGLE_ACTIVE_COLUMN = (state) => {
  state.activeColumn = !state.activeColumn;
  return localforage.setItem("config/activeColumn", state.activeColumn);
};

export const SET_ACTIVE_COLUMN = (state, trueFalse) => {
  state.activeColumn = trueFalse;
  return localforage.setItem("config/activeColumn", state.activeColumn);
};

export const TOGGLE_LENGTH_COLUMN = (state) => {
  state.lengthColumn = !state.lengthColumn;
  return localforage.setItem("config/lengthColumn", state.lengthColumn);
};

export const SET_LENGTH_COLUMN = (state, trueFalse) => {
  state.lengthColumn = trueFalse;
  return localforage.setItem("config/lengthColumn", state.lengthColumn);
};

export const TOGGLE_SERIAL_COLUMN = (state) => {
  state.serialColumn = !state.serialColumn;
  return localforage.setItem("config/serialColumn", state.serialColumn);
};

export const SET_SERIAL_COLUMN = (state, trueFalse) => {
  state.serialColumn = trueFalse;
  return localforage.setItem("config/serialColumn", state.serialColumn);
};

export const TOGGLE_LINES_COLUMN = (state) => {
  state.linesColumn = !state.linesColumn;
  return localforage.setItem("config/linesColumn", state.linesColumn);
};

export const SET_LINES_COLUMN = (state, trueFalse) => {
  state.linesColumn = trueFalse;
  return localforage.setItem("config/linesColumn", state.linesColumn);
};

export const SET_HIGHLIGHT_COLOR = (state, color) => {
  state.highlightColor = color ? color : "#FFEE55";
  return localforage.setItem("config/highlightColor", state.highlightColor);
};

export const SET_FONT_SIZE = (state, size) => {
  state.fontSize = size ? size : 16;
  return localforage.setItem("config/fontSize", state.fontSize);
};

export const SET_PLAY_INTERVAL = (state, duration) => {
  if (duration == null) {
    duration = 120;
  }
  state.playInterval = duration;
  return localforage.setItem("config/playInterval", state.playInterval);
};

export const SET_RENDER_FILE_BASE_NAME = (state, basename) => {
  if (basename == null) {
    basename = "mkb-part-0";
  }
  state.renderFileBaseName = basename && basename.trim() ? basename.trim() : "";
  return localforage.setItem(
    "config/renderFileBaseName",
    state.renderFileBaseName
  );
};

export const SET_RENDER_BASE_DIRECTORY = (state, baseDir) => {
  if (baseDir == null || baseDir.trim().length < 3) {
    log.info("baseDir can not be less than 3 charecters");
    baseDir = "/excelite/";
  }
  baseDir = baseDir && baseDir.trim() ? baseDir.trim() : "";
  state.renderBaseDirectory = baseDir
    .replace(/\\/g, "/")
    .replace(/\/\/+/g, "/");
  return localforage.setItem(
    "config/renderBaseDirectory",
    state.renderBaseDirectory
  );
};

export const SET_RENDER_FONT_SIZE = (state, size) => {
  state.renderFontSize = size ? size : 48;
  return localforage.setItem("config/renderFontSize", state.renderFontSize);
};

export const SET_RENDER_LINE_HEIGHT = (state, height) => {
  state.renderLineHeight = height ? height : 1.2;
  return localforage.setItem("config/renderLineHeight", state.renderLineHeight);
};

export const SET_RENDER_SERVER_URL = (state, url) => {
  state.renderServerURL = url ? url : "http://localhost:3132";
  return localforage.setItem("config/renderServerURL", state.renderServerURL);
};

export const SET_RENDER_BATCH = (state, trueFalse) => {
  state.renderBatch = trueFalse != null ? trueFalse : true;
  return localforage.setItem("config/renderBatch", state.renderBatch);
};
export const TOGGLE_RENDER_BATCH = (state) => {
  state.renderBatch = !state.renderBatch;
  return localforage.setItem("config/renderBatch", state.renderBatch);
};

export const CHECK_SERVER_RUNNING = (state) => {
  // log.info("state.axios_checking", state.axios_checking);
  if (process.env.IS_ELECTRON || state.axios_checking == true) {
    return;
  }
  state.axios_checking = true;
  return axiosRaw
    .get(state.renderServerURL)
    .then((res) => {
      state.isServerRunning = true;
      return Promise.resolve(state.isServerRunning);
    })
    .catch((err) => {
      state.isServerRunning = false;
      return Promise.reject(state.isServerRunning);
    })
    .finally(() => {
      setTimeout(() => {
        state.axios_checking = false;
      }, 5000);
      log.info("isServerRunning----- ", state.isServerRunning);
    });
};

export const SET_TAB_2_COLUMN = (state, trueFalse) => {
  state.tab2Column = trueFalse;
  return localforage.setItem("config/tab2Column", state.tab2Column);
};

export const TOGGLE_TAB_2_COLUMN = (state) => {
  state.tab2Column = !state.tab2Column;
  return localforage.setItem("config/tab2Column", state.tab2Column);
};

export const SET_ENABLE_SPELL_CHECK = (state, trueFalse) => {
  state.enableSpellCheck = trueFalse;
  return localforage.setItem("config/enableSpellCheck", state.enableSpellCheck);
};

export const TOGGLE_ENABLE_SPELL_CHECK = (state) => {
  state.enableSpellCheck = !state.enableSpellCheck;
  return localforage.setItem("config/enableSpellCheck", state.enableSpellCheck);
};

export const SET_ENABLE_FILTERS = (state, trueFalse) => {
  state.enableFilters = trueFalse;
  return localforage.setItem("config/enableFilters", state.enableFilters);
};

export const TOGGLE_ENABLE_FILTERS = (state) => {
  state.enableFilters = !state.enableFilters;
  return localforage.setItem("config/enableFilters", state.enableFilters);
};

export const SET_BREAK_LINE = (state, trueFalse) => {
  state.breakLine = trueFalse;
  return localforage.setItem("config/breakLine", state.breakLine);
};

export const TOGGLE_BREAK_LINE = (state) => {
  state.breakLine = !state.breakLine;
  return localforage.setItem("config/breakLine", state.breakLine);
};

export const SET_BREAK_LINE_WORD_LIMIT = (state, val) => {
  state.breakLineWordLimit = val;
  return localforage.setItem(
    "config/breakLineWordLimit",
    state.breakLineWordLimit
  );
};

export const SET_BREAK_LINE_ELLIPSIS = (state, val) => {
  state.breakLineEllipsis = val;
  return localforage.setItem(
    "config/breakLineEllipsis",
    state.breakLineEllipsis
  );
};
export const SET_SLUG_BAND_OFFSET = (state, valXY) => {
  state.slugBandOffset = { x: parseFloat(valXY.x), y: parseFloat(valXY.y) };
  return localforage.setItem("config/slugBandOffset", state.slugBandOffset);
};
