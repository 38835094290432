<template>
<v-card-actions style="width:200px" class="mr-0 pr-0">
  <v-chip small label class="mb-6">{{ selectedRow.customFontSize ? selectedRow.customFontSize : this.defaultSize }}</v-chip>
  <v-slider
    v-model="fontSize"
    :hint="'Font size: '+ fontSize"
    :max="renderFontSize"
    :min="renderFontSize - 4"
  ></v-slider>
</v-card-actions>
</template>

<script>
import { mapGetters } from "vuex";
import bus from "@/bus";
import log from "@/log";
export default {
  name:"font-size-slider",
  props:['row'],
  data(){
    return {
      fontSize: 48,
      defaultSize: 48,
    }
  },
  watch: {
    fontSize(val){
      this.$emit('sizeChanged', val)
    },
    row(val){
      this.fontSize = this.row.customFontSize ? this.row.customFontSize : this.defaultSize;
    }
  },
  computed:{
    ...mapGetters({
      authData: "app/auth/authData",
      selectedRow: "app/home/selectedRow",
      renderFontSize: "app/config/renderFontSize",
    }),
  },
  mounted() {
    this.fontSize = this.row.customFontSize ? this.row.customFontSize : this.defaultSize;
  }

}
</script>

<style>

</style>