<template>
  <v-card elevation="0" class="ma-0 pa-0 pl-2">
    <input
      id="path-picker"
      ref="pathPicker"
      type="file"
      webkitdirectory
      directory
      style="display: none"
    />
    <v-card-actions class="ma-0 pa-0">
      <span
        v-tooltip="'Output folder'"
        class="path-string"
        @click.stop="
          openOutputFolder($store.getters['app/config/renderBaseDirectory'])
        "
      >
        <v-icon left>mdi-folder-search</v-icon>
        {{ this.$store.getters["app/config/renderBaseDirectory"] }}
      </span>
      <v-spacer></v-spacer>
      <v-btn
        v-if="IS_ELECTRON"
        v-tooltip="'Open output folder'"
        icon
        @click.stop="setOutputFolder"
        color="indigo darken-2"
      >
        <v-icon>mdi-folder-open</v-icon>
      </v-btn>
      <file-path-dialog v-else />
    </v-card-actions>
  </v-card>
</template>

<script>
import log from "@/log";
import { remote, shell } from "@/../electron/client-plugins/electron";
import FilePathDialog from "./FilePathDialog";
const { dialog } = remote;
export default {
  components: { FilePathDialog },
  computed: {
    IS_ELECTRON() {
      return process.env.IS_ELECTRON;
    },
  },
  methods: {
    async openOutputFolder(fullpath) {
      let error = await shell.openPath(fullpath);
      if (error) {
        this.setOutputFolder();
      }
    },
    setOutputFolder() {
      dialog
        .showOpenDialog({
          properties: ["openDirectory"],
        })
        .then((result) => {
          if (!result.canceled) {
            log.info("outputPath", result.filePaths[0]);
            const path = result.filePaths[0].replace(/\\/g, "/") + "/";
            this.$store.dispatch("app/config/setRenderBaseDirectory", path);
            this.$emit("selected", path);
          }
        });
    },
  },
};
</script>

<style>
.path-string {
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 1rem;
  font-size: 0.8rem;
  cursor: pointer;
  color: grey;
  border-radius: 5px;
  padding: 5px;
}
.path-string:hover {
  color: black;
  background: ghostwhite;
}
</style>