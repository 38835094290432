import log from "@/log";
import Login from "./pages/Login.vue";
import Signup from "./pages/Signup.vue";
import store from "@/store";
export default [
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: { guest: true },
  },
  {
    path: "/logout",
    name: "logout",
    meta: { requiredAuth: true },
    component: {
      beforeRouteEnter(to, from, next) {
        log.info("logging out...");
        store.dispatch("app/auth/logoutUser").finally(() => {
          setTimeout(() => {
            next({ name: "login" });
          }, 400);
        });
      },
    },
  },
  {
    path: "/signup",
    name: "signup",
    component: Signup,
    meta: { guest: true },
  },
];
