import log from "@/log";
import store from "@/store";
import bus from "@/bus";
import { getRandomString } from "@/helpers/utils";
export default (evt) => {
  const randomStr = "-" + getRandomString(5);

  let rows = store.getters["app/home/rows"];
  let e = evt.$event;
  let row = evt.row;
  e.preventDefault();

  let menu = document.createElement("div");
  menu.id = "ctxmenu" + randomStr;
  menu.classList.add("ctxmenu", "noselect");

  menu.onmouseleave = () => (menu.outerHTML = "");
  menu.onclick = () => (menu.outerHTML = "");
  menu.onblur = () => (menu.outerHTML = "");

  const elmIDMoveUp = "ctxMoveUp" + randomStr;
  const elmIDMoveDown = "ctxMoveDown" + randomStr;
  const elmIDInsertAbove = "ctxInsertAbove" + randomStr;
  const elmIDInsertBelow = "ctxInsertBelow" + randomStr;
  const elmIDUndo = "ctxUndo" + randomStr;
  const elmIDRedo = "ctxRedo" + randomStr;
  const elmIDDelete = "ctxDelete" + randomStr;

  menu.innerHTML = `
    <div class="subheader" >
      Row Number ${row.sn}
      <span style="float:right;">✖</span>
    </div>
    <p id="${elmIDMoveUp}" >▲ Move up</p>
    <p id="${elmIDMoveDown}" >▼ Move down</p>
    <p class="separator"></p>
    <p id="${elmIDInsertAbove}" >↱ Insert row above</p>
    <p id="${elmIDInsertBelow}" >↳ Insert row below</p>
    <p class="separator"></p>
    <p id="${elmIDUndo}" >⟲ Undo text change</p>
    <p id="${elmIDRedo}" >⟳ Redo text change</p>
    <p class="separator"></p>
    <p id="${elmIDDelete}">🗑 Delete (can't undo)</p>
  `;

  document.body.appendChild(menu);

  // console.log("menu.clientWidth", menu.clientWidth);
  // console.log("window.innerWidth", window.innerWidth);
  // console.log("e.pageX", e.pageX);
  // //position menu start
  let menuPosY = e.pageY;
  let menuPosX = e.pageX;

  if (window.innerHeight < e.pageY + menu.clientHeight) {
    menuPosY = e.pageY - menu.clientHeight;
    menuPosY = menuPosY + 50;
  } else {
    menuPosY = menuPosY - 50;
  }
  if (window.innerWidth < e.pageX + menu.clientWidth) {
    menuPosX = e.pageX - menu.clientWidth;
  }
  menu.style = `top:${menuPosY}px;left:${menuPosX - 50}px`;
  // position menu end

  let elmMoveUp = document.getElementById(elmIDMoveUp);
  let elmMoveDown = document.getElementById(elmIDMoveDown);
  let elmInsertAbove = document.getElementById(elmIDInsertAbove);
  let elmInsertBelow = document.getElementById(elmIDInsertBelow);
  let elmUndo = document.getElementById(elmIDUndo);
  let elmRedo = document.getElementById(elmIDRedo);
  let elmDelete = document.getElementById(elmIDDelete);

  elmMoveUp.onclick = () => {
    if (row.sn == 1 || row.sn == rows.length) {
      return;
    }
    rows.move(row.sn - 1, row.sn - 2, 1);
    bus.$emit("selectRow", row.sn - 1);
    // bus.$emit("scrollToRow", row.sn - 1);
  };
  elmMoveDown.onclick = () => {
    if (row.sn > rows.length - 2) {
      return;
    }
    rows.move(row.sn - 1, row.sn, 1);
    bus.$emit("selectRow", row.sn + 1);
    // bus.$emit("scrollToRow", row.sn + 1);
  };

  // this code clock is working fine, uncomment if you like.
  elmInsertAbove.onclick = () => {
    store.dispatch("app/home/addRow", {
      atIndex: row.sn - 1,
    });
  };
  elmInsertBelow.onclick = () => {
    store.dispatch("app/home/addRow", {
      atIndex: row.sn,
    });
    bus.$emit("selectRow", row.sn + 1);
  };

  elmDelete.onclick = () => {
    store.dispatch("app/home/removeRow", row);
    // bus.$emit("selectRow", row.sn + 1);
  };

  // if (row.historyPointer < row.history.length - 1 && row.historyPointer > 0) {
  //   // can undo and redo
  // } else if(){

  // }

  // elmUndo.setAttribute("style", "color: grey;");
  // elmRedo.setAttribute("style", "color: grey;");

  elmUndo.onclick = (e) => {
    store.dispatch("app/home/rowUndo", row);
  };
  elmRedo.onclick = (e) => {
    store.dispatch("app/home/rowRedo", row);
  };
};
