<template>
  <v-row class="fill-height justify-center align-center d-flex">
    <v-col cols="6" class="">
      <v-card-title class="py-12 black--text text-center justify-center">
        Refreshing, Please wait...
      </v-card-title>
      <v-progress-linear
        color="deep-purple accent-4"
        indeterminate
        rounded
        height="6"
      ></v-progress-linear>
    </v-col>
  </v-row>
</template>

<script>
export default {
  mounted() {
    setTimeout(() => {
      this.$router.replace({ name: "home", force: true });
    }, 500);
  },
};
</script>

<style>
</style>