<template>
  <div pageTitle="Sign Up Form">
    Sign Up Form
    <!-- <ion-card>
      <ion-card-header>
        <ion-card-title>SignUp</ion-card-title>
      </ion-card-header>
      <ion-card-content>
        <ion-item>
          <ion-label position="floating">Username</ion-label>
          <ion-input type="text" v-model="userInfo.username"></ion-input>
        </ion-item>
        <ion-item>
          <ion-label position="floating">Email</ion-label>
          <ion-input type="email" v-model="userInfo.email"></ion-input>
        </ion-item>
        <ion-item>
          <ion-label position="floating">Password</ion-label>
          <ion-input type="password" v-model="userInfo.password"></ion-input>
        </ion-item>
        <ion-button expand="full" @click="login()">SignUp</ion-button>
      </ion-card-content>
    </ion-card> -->
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  components: {},
  data() {
    return {
      userInfo: {
        email: "aman@khare.co.in",
        password: "passwd",
      },
    };
  },
  computed: {
    ...mapGetters("app/auth", {
      loginStatus: "getLoginStatus",
    }),
  },
  methods: {
    ...mapActions("app/auth", {
      signupUser: "signupUser",
    }),
    async login() {
      if (this.userInfo.email && this.userInfo.password) {
        await this.signupUser(this.userInfo);
        if (this.loginStatus === "success") {
          this.$router.replace({ name: "login" });
        } else {
          alert("falied to login");
        }
      }
    },
  },
};
</script>