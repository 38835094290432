import log from "@/log";
import store from "@/store";

function guard(to, from, next, authData) {
  if (to.meta && to.meta.requiredAuth) {
    if (!authData || authData.uid < 1) {
      return next({ name: "login" });
    }
    if (to.meta.roles && !to.meta.roles.includes(authData.role)) {
      log.warn("Not authorised, redirecting to home.");
      return next({ name: "home" });
    }
    return next();
  } else {
    if (to.meta.guest && authData && authData.uid > 0) {
      return next({ name: "home" });
    }
    return next();
  }
}

export default (to, from, next) => {
  let authData = store.getters["app/auth/authData"];
  if (!authData || authData.uid == 0) {
    store.dispatch("app/auth/loadStorageTokens").then(
      () => {
        authData = store.getters["app/auth/authData"];
        return guard(to, from, next, authData);
      },
      (error) => {
        log.error(error);
        return guard(to, from, next, authData);
      }
    );
  } else {
    return guard(to, from, next, authData);
  }
};
