export const resetHistory = (rows) => {
  rows = rows.map(row =>{
    row = resetRowHistory(row);
    return row;
  })
  return rows;
};

export const resetRowHistory = (row) => {
  row.history = [row.text];
  row.historyPointer = 0;
  return row;
};

export const makeHistory = (row) => {
  let history = [];  
  let historyPointer = -1;
  if (!row.history) {
    row.history =[]
  }
  history.push(...row.history)


  if (!row || !row.text || row.text.trim().length < 1) {
    return {history, historyPointer};
  }
    
  let oldIndex = row.history.findIndex((txt) => txt.trim() == row.text.trim());
  
  
  if (oldIndex > -1) {
    historyPointer = oldIndex;
  } else {
    history.unshift(row.text);
    historyPointer = 0;
  }

  return {history, historyPointer};
};

export const addHistoryObserver = (row) => {
  console.log('addHistory', row.sn, row.text)
  if (!row.history) {
    row.history = [];
  }

  if (!row.text || row.text.trim().length < 1) {
    return row;
  }

  let oldIndex = row.history.findIndex((txt) => txt.trim() == row.text.trim());
  if (oldIndex > -1) {
    row.historyPointer = oldIndex;
  } else {
    row.history.unshift(row.text);
    row.historyPointer = 0;
  }

  return row;
};

export const undo = (row) => {
  if (!row.history) {
    row.history = [];
  }
  if (!row.historyPointer) {
    row.historyPointer = 0;
  }

  const desiredItem = row.history[row.historyPointer + 1];
  if (!desiredItem || desiredItem.trim().length < 1) {
    return row;
  }

  row.text = desiredItem.trim();
  row.length = row.text.length;
  row.historyPointer++;

  return row;
};

export const redo = (row) => {
  if (!row.history) {
    row.history = [];
  }
  if (!row.historyPointer) {
    row.historyPointer = 0;
  }
  const desiredItem = row.history[row.historyPointer - 1];
  if (!desiredItem || desiredItem.trim().length < 1) {
    return row;
  }
  row.text = desiredItem.trim();
  row.length = row.text.length;
  row.historyPointer--;
  return row;
};
