<template>
  <v-sheet ref="canvasWrapper" id="canvasWrapper">
    <div style="width: 100%; background-color: #333">
      <canvas
        ref="canvas"
        :width="size.width"
        :height="size.height"
        style="border-bottom: 1px solid #333"
      ></canvas>
    </div>
    <path-picker-electron v-if="$store.getters['app/config/isRendererOK']" />
    <v-card flat tile>
      <v-subheader>
        <span v-if="badRows && badRows.length > 0">
          {{
            badRows.length > 1
              ? badRows.length + " rows "
              : badRows.length + " row "
          }}
          needs attention.
        </span>

        <v-spacer></v-spacer>

        <font-size-slider
          :row="selectedRow"
          @sizeChanged="handleFontSizeChanged"
          class="mt-6"
        />
      </v-subheader>
      <v-card-actions
        v-if="badRows && badRows.length > 0"
        class="justify-space-around bad-rows"
      >
        <v-btn
          @click.stop="scrollToRow(row.sn)"
          icon
          :class="
            row.text == '-' ? 'blue white--text mr-1' : 'red white--text mr-1'
          "
          v-for="row in badRows"
          :key="row.sn"
        >
          {{ row.sn }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-sheet>
</template>
<script>
import { mapGetters } from "vuex";
import { fabric } from "fabric";
import bus from "@/bus";
import log from "@/log";
import { padZero } from "@/helpers/utils";
import moment from "@/plugins/moment";
import PathPickerElectron from "./PathPickerElectron";
import SheetExport from "../DataEditor/SheetExport";
import ElectronRenderer from "./ElectronRenderer";
import ServerRenderer from "./ServerRenderer";
import FontSizeSlider from "@/app/config/modules/FontSizeSlider";
import SendXLSX from "../DataEditor/SendXLSX";
import GenerateWorkbook from "../DataEditor/GenerateWorkbook";
export default {
  components: { PathPickerElectron, FontSizeSlider },
  props: [],
  emits: [],
  data() {
    return {
      outputPath: "",
      canvas: null,
      parent: null,
      slugObj: null,
      targetWidth: 1920,
      targetHeight: 1080,
      targetRatio: 1.778,
      scalerValue: 1,
      refRectX: 320 + this.$store.getters["app/config/slugBandOffset"].x,
      refRectY: 805 + this.$store.getters["app/config/slugBandOffset"].y,
      refRectW: 1010,
      refRectH: 120,

      imageBand: "/img/mkb/mkb-band.png",
      imageJacket: "/img/mkb/mkb-jacket-all-no-ticker.png",
      imageBackground: "/img/mkb/mkb-background.png",
      imageHolderBand: null,
      imageHolderJacket: null,
      imageHolderBackground: null,
      isChecked: false,
      isRendering: false,
      checkInterval: null,
      renderInterval: null,
      renderMoment: "",
      badRows: [],
    };
  },
  watch: {
    parent() {
      if (this.parent && this.parent.clientWidth) {
        this.resize();
      }
    },

    selectedRow(row) {
      if (!row || !row.sn || !row.text) {
        row = {
          sn: 0,
          text: "",
        };
      }
      this.setSlugObjText({
        text: row.text,
        sn: row.sn,
        customFontSize: row.customFontSize,
      });
    },
  },
  computed: {
    ...mapGetters({
      authData: "app/auth/authData",
      selectedRow: "app/home/selectedRow",
      largeRows: "app/home/largeRows",
      renderStatus: "app/home/renderStatus",
      renderFontSize: "app/config/renderFontSize",
      renderLineHeight: "app/config/renderLineHeight",
    }),
    IS_ELECTRON() {
      return process.env.IS_ELECTRON;
    },
    size() {
      if (!this.parent) {
        return { width: 100, height: 100, parentHeight: 100 };
      }

      let pWidth = this.parent.clientWidth;
      let pHeight = this.parent.clientHeight;

      let width = pWidth;
      let height = pHeight;

      if (pWidth > pHeight) {
        if (pWidth > pHeight * this.targetRatio) {
          // uncomment and comment the line at the end to enable height calculation as well
          //width = pHeight * this.targetRatio;
        }
        height = width / this.targetRatio;
      }

      // // uncomment and uncomment the line at top to enable height calculation as well
      // if (pHeight > pWidth) {
      //   height = pWidth / this.targetRatio;
      // }

      // if (pHeight == pWidth) {
      //   height = pWidth / this.targetRatio;
      // }

      this.scalerValue = width / this.targetWidth;
      return { width, height, parentHeight: this.parent.clientHeight };
    },
  },
  methods: {
    handleFontSizeChanged(size) {
      size = size ? size : this.renderFontSize;
      this.$store.dispatch("app/home/setRowCustomFontSize", {
        row: this.selectedRow,
        customFontSize: size,
      });
      this.setSlugObjText(this.selectedRow);
    },
    resize() {
      this.canvas.setHeight(this.size.height);
      this.canvas.setWidth(this.size.width);
      this.canvas.calcOffset();
      this.canvas.renderAll();
    },
    alsoExportXLSX() {
      if (process.env.IS_ELECTRON) {
        SheetExport({
          filename: this.$store.getters["app/config/renderFileBaseName"],
          allColumns: false,
          withHeader: false,
          dontAskForDestination: true,
        });
      } else {
        this.sendXLSX();
      }
    },

    async sendXLSX() {
      const workbook = GenerateWorkbook({
        allColumns: false,
        withHeader: false,
      });

      const data = {
        workbook,
        baseDirectory: this.$store.getters["app/config/renderBaseDirectory"],
        title: this.$store.getters["app/config/renderFileBaseName"],
        folder:
          this.$store.getters["app/config/renderFileBaseName"] +
          "-" +
          this.renderMoment,
      };

      return SendXLSX(data);
    },
    async updateSlugPosition() {
      this.canvas.renderAll();
      return new Promise((resolve) => {
        if ([1, 2].includes(this.slugObj._textLines.length)) {
          let topRHS =
            this.refRectY * this.scalerValue +
            (this.refRectH * this.scalerValue) / 2;
          let topLHS = this.slugObj.height / 2;

          let top = topRHS - topLHS;
          this.slugObj.top = top;
        }
        let partialRow = {
          sn: this.slugObj.sn,
          lines: this.slugObj._textLines.length,
          text: this.slugObj.text,
        };
        this.$store.dispatch("app/home/setRowLines", partialRow);
        this.pushBadRows(partialRow);
        this.canvas.renderAll();
        this.canvas.bringToFront(this.slugObj);
        this.canvas.renderAll();

        resolve(true);
      });
    },
    async setSlugObjText({ text, sn = 0, customFontSize = null }) {
      if (!this.slugObj) {
        return;
      }
      if (!text || text.trim().length < 1) {
        text = "";
      }
      this.slugObj.sn = sn;
      this.slugObj.text = this.$store.getters["app/config/renderTextUpperCase"]
        ? text.toUpperCase()
        : text;
      this.slugObj.left = this.refRectX * this.scalerValue;
      this.slugObj.top = this.refRectY * this.scalerValue;
      this.slugObj.width = this.refRectW * this.scalerValue;
      this.slugObj.height = this.refRectH * this.scalerValue;
      this.slugObj.fixedWidth = this.refRectW * this.scalerValue;
      this.slugObj.fixedHeight = this.refRectH * this.scalerValue;
      this.slugObj.fontSize =
        (customFontSize ? customFontSize : this.renderFontSize) *
        this.scalerValue;
      this.slugObj.lineHeight = this.renderLineHeight;
      this.slugObj.fontFamily =
        this.$store.getters["app/config/renderFontName"];

      await this.updateSlugPosition();
    },
    async toDataURL(row) {
      this.imageHolderJacket.opacity = 0;
      this.canvas.renderAll();

      this.setSlugObjText({ text: "", sn: 0, customFontSize: null });
      return new Promise(async (resolve) => {
        this.setSlugObjText({
          text: row.text,
          sn: row.sn,
          customFontSize: row.customFontSize,
        });
        this.canvas.renderAll();
        const dataURL = this.canvas.toDataURL({
          width: this.canvas.width,
          height: this.canvas.height,
          left: 0,
          top: 0,
          format: "png",
          multiplier: 1 / this.scalerValue,
        });
        let partialRow = {};
        partialRow.sn = this.slugObj.sn;
        partialRow.text = this.slugObj.text;
        partialRow.length = this.slugObj.text.length;
        partialRow.lines = this.slugObj._textLines.length;
        this.imageHolderJacket.opacity = 1;
        this.canvas.renderAll();
        resolve({ dataURL, row: partialRow });
      });
    },
    async downloadPNG(row) {
      return this.checkRow(row)
        .then((response) => {
          let { dataURL } = response; //await this.toDataURL(row);

          var link = document.createElement("a");
          link.download = this.targetFileNameString({
            extn: ".png",
            sn: row.sn,
          });
          link.href = dataURL;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((response) => {
          this.$store.dispatch("setAlert", {
            message: "This row has errors, Can not export.",
            type: "red white--text",
          });
        });
    },
    initSlugObj() {
      this.slugObj = new fabric.Textbox("", {
        fontFamily: this.$store.getters["app/config/fontName"],
        textAlign: "left",
        angle: 0,
        lockMovementX: true,
        lockMovementY: true,
        hasControls: false,
        hasBorders: false,
        maxLines: 2,
        sn: 0,
      });
      this.canvas.add(this.slugObj);
    },
    initImages(success = () => {}) {
      fabric.Image.fromURL(
        this.imageBand,
        (imageBnd) => {
          imageBnd.scale(this.scalerValue);
          imageBnd.set({
            left:
              this.$store.getters["app/config/slugBandOffset"].x *
              this.scalerValue,
            top:
              this.$store.getters["app/config/slugBandOffset"].y *
              this.scalerValue,
            selectable: false,
            evented: false,
            hasBorders: false,
          });

          fabric.Image.fromURL(
            this.imageJacket,
            (imageJkt) => {
              imageJkt.scale(this.scalerValue);
              imageJkt.set({
                left: 0,
                top: 0,
                selectable: false,
                evented: false,
                hasBorders: false,
              });

              this.imageHolderBand = imageBnd;
              this.imageHolderJacket = imageJkt;
              this.canvas.add(this.imageHolderBand);
              this.canvas.add(this.imageHolderJacket);
              success();
            },
            {
              crossOrigin: "Anonymous",
            }
          );
        },
        {
          crossOrigin: "Anonymous",
        }
      );
    },
    initCanvas() {
      this.parent = this.$refs.canvasWrapper.$el;
      this.canvas = new fabric.Canvas(this.$refs.canvas, {
        backgroundColor: "rgba(0,0,0,0.0)",
      });

      this.initImages(() => {
        this.initSlugObj();
        this.canvas.bringToFront(this.slugObj);
        this.setSlugObjText({
          text: this.selectedRow.text,
          sn: this.selectedRow.sn,
          customFontSize: this.selectedRow.customFontSize,
        });
        this.canvas.renderAll();
      });
    },
    pushBadRows(row) {
      const oldRow = this.badRows.find((el) => el.sn == row.sn);

      if (oldRow) {
        this.badRows = this.badRows.filter((el) => el.sn != row.sn);
        if (row.lines > 2 || row.lines < 1 || row.text == "-") {
          this.badRows.push(row);
        }
      } else {
        if (row.lines > 2 || row.lines < 1 || row.text == "-") {
          this.badRows.push(row);
        }
      }

      this.badRows = this.badRows.filter((el) => {
        return this.$store.getters["app/home/rows"].find(
          (elm) => elm.sn == el.sn
        );
      });
    },
    checkRow(row) {
      return this.toDataURL(row).then((response) => {
        this.$store.dispatch("app/home/setSelectedRow", response.row);
        return new Promise((resolve, reject) => {
          if (response.row.text.toLowerCase() != row.text.toLowerCase()) {
            // not the same row
            log.info("not the same row", row);
            return reject(response);
          }
          if ([1, 2].includes(response.row.lines) && row.text != "-") {
            return resolve(response);
          } else {
            return reject(response);
          }
        });
      });
    },
    targetFileNameString({ extn = ".png", sn } = {}) {
      return (
        this.$store.getters["app/config/renderFileBaseName"] +
        "-" +
        padZero(sn, 4) +
        extn
      );
    },
    async makePNG({ dataURL, row }) {
      const data = {
        imgData: dataURL,
        title: this.targetFileNameString({ extn: "", sn: row.sn }),
        baseDirectory: this.$store.getters["app/config/renderBaseDirectory"],
        folder:
          this.$store.getters["app/config/renderFileBaseName"] +
          "-" +
          this.renderMoment,
      };

      // if(this.$store.getters['isLocked']) {
      //   console.log("something went wrong.");
      //   return false;
      // }

      if (this.IS_ELECTRON) {
        return await ElectronRenderer(data);
      } else {
        return await ServerRenderer(data);
      }
    },
    getRandomNumberBetween(min, max) {
      return Math.floor(Math.random() * (max - min + 1) + min);
    },
    async renderRows({ onSuccess, onError }) {
      if (this.badRows.length > 0) {
        log.error(
          "can not render, there are error in rows",
          this.badRows.length
        );
        this.$store.dispatch("app/home/setRenderStatus", null);
        onError({ rows: this.badRows });
        return;
      }

      this.$store.dispatch("app/home/setRenderStatus", "rendering");
      const rows = this.$store.getters["app/home/rowsWithData"];

      let index = 0;
      let indexHolder = 0;
      let maxAlowed = this.getRandomNumberBetween(0, rows.length - 1);
      this.checkInterval = setInterval(() => {
        if (this.$store.getters["isLockedRenderLimit"]) {
          if (indexHolder > maxAlowed) {
            indexHolder = maxAlowed;
          }
          console.log("rendering", indexHolder, maxAlowed);
        }

        this.$store.dispatch("app/home/setSelectedRow", rows[indexHolder]);

        this.checkRow(rows[indexHolder])
          .then((response) => {
            // ROW OK, SAVE/EMIT HERE
            this.makePNG(response); //.then((res) => {});

            if (index >= rows.length - 1) {
              // no more rows left.
              clearInterval(this.checkInterval);
              this.$store.dispatch("app/home/setRenderStatus", null);
              this.alsoExportXLSX();
              onSuccess();
            } else {
              index++;
              indexHolder = index;
            }
          })
          .catch((response) => {
            clearInterval(this.checkInterval);
            this.$store.dispatch("app/home/setRenderStatus", null);
            this.scrollToRow(rows[index].sn);
            onError({ row: rows[index] });
          });
      }, 200);
    },
    scrollToRow(sn) {
      setTimeout(() => {
        bus.$emit("selectRow", sn);
        bus.$emit("scrollToRow", sn);
        // setTimeout(() => {
        //   bus.$emit("selectRow", sn);
        //   bus.$emit("scrollToRow", sn);
        // }, 100);
      }, this.$store.getters["app/config/playInterval"] / 1.5);
    },
    async handleCheck({ onSuccess, onError }) {
      this.$store.dispatch("app/home/setRenderStatus", "checking");
      const rows = this.$store.getters["app/home/rowsWithData"];
      let index = 0;
      this.checkInterval = setInterval(() => {
        this.$store.dispatch("app/home/setSelectedRow", rows[index]);
        this.checkRow(rows[index])
          .then((response) => {
            if (index >= rows.length - 1) {
              // no more rows left.
              clearInterval(this.checkInterval);
              this.$store.dispatch("app/home/setRenderStatus", null);
              if (this.badRows.length == 0) {
                onSuccess();
              } else {
                onError({ rows: this.badRows.length });
              }
            } else {
              index++;
            }
          })
          .catch((response) => {
            // this segment stop execution as soon as error occures.

            if (false) {
              clearInterval(this.checkInterval);
              this.$store.dispatch("app/home/setRenderStatus", null);
              this.scrollToRow(rows[index].sn);
              onError({ row: rows[index] });
            } else {
              if (index < rows.length - 1) {
                this.scrollToRow(rows[index].sn);
                console.error("error on row", rows[index].sn);
                index++;
              } else {
                clearInterval(this.checkInterval);
                this.$store.dispatch("app/home/setRenderStatus", null);
                this.scrollToRow(rows[index].sn);
                onError({ row: rows[index] });
              }
            }
          });
      }, this.$store.getters["app/config/playInterval"]);
    },
    filterBadRows() {
      this.badRows = this.$store.getters["app/home/rowsWithData"].filter(
        (el) => el.lines > 2 || el.lines < 1
      );
    },
  },

  beforeDestroy() {
    bus.$off("renderSingle");
    bus.$off("renderAll");
    bus.$off("renderSelected");
    bus.$off("renderCancel");
    this.badRows = [];
  },
  updated() {
    this.$store.dispatch("app/config/checkServerRunning");
  },
  mounted() {
    this.$nextTick(() => {
      this.filterBadRows();
    });
    this.initCanvas();
    this.scrollToRow(this.selectedRow.sn);

    bus.$on("renderCancel", () => {
      clearInterval(this.checkInterval);
      this.$store.dispatch("app/home/setRenderStatus", null);
      this.$store.dispatch("app/home/selectFirstRow");
    });
    bus.$on("renderSingle", () => {
      if (this.$store.getters["isLocked"]) {
        this.$store.dispatch("app/home/selectFirstRow");
        this.$store.dispatch("app/home/setLoopRows", false);
        setTimeout(() => {
          this.downloadPNG(this.selectedRow);
        }, 5000);
      } else {
        this.downloadPNG(this.selectedRow);
      }
    });

    bus.$on("renderAll", () => {
      this.$store.dispatch("app/home/setLoopRows", false);
      this.$store.dispatch("app/home/selectFirstRow");
      setTimeout(() => {
        this.handleCheck({
          onSuccess: () => {
            log.warn("success", "You may render these rows");
            this.$store.dispatch("app/home/selectFirstRow");
            this.renderMoment = moment().format("YYYYMMDD-HHmm");
            setTimeout(() => {
              this.scrollToRow(1);
              this.$store.dispatch("app/home/setLoopRows", false);
              this.renderRows({ onSuccess: () => {}, onError: () => {} });
            }, 500);
          },
          onError: () => {
            log.error("an error occured during check");
            setTimeout(() => {
              this.$store.dispatch("app/home/setRenderStatus", null);
              // this.$router.replace({ name: "refresher" });
            }, 500);
          },
        });
      }, 200);
    });

    bus.$on("renderSelected", () => {
      return;
      this.$store.dispatch("app/home/setLoopRows", false);
      this.$store.dispatch("app/home/selectFirstRow");
      setTimeout(() => {
        this.handleCheck({
          onSuccess: () => {
            log.warn("success", "You may render these rows");
            this.$store.dispatch("app/home/selectFirstRow");
            this.renderMoment = moment().format("YYYYMMDD-HHmm");
            setTimeout(() => {
              this.scrollToRow(1);
              this.$store.dispatch("app/home/setLoopRows", false);
              this.renderRows({ onSuccess: () => {}, onError: () => {} });
            }, 500);
          },
          onError: () => {
            log.error("an error occured during check");
            setTimeout(() => {
              this.$store.dispatch("app/home/setRenderStatus", null);
              // this.$router.replace({ name: "refresher" });
            }, 500);
          },
        });
      }, 200);
    });
  },
};
</script>
<style scoped>
.bad-rows {
  overflow-x: scroll;
}
</style>