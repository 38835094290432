<template>
  <v-col cols="12" md="5" class="ma-0 pa-0">
    <div class="renderpane">
      <data-render-toolbar />

      <!-- Blank Unicode Charecter '⠀'-->
      <data-render-canvas />
    </div>
  </v-col>
</template>

<script>
import { mapGetters } from "vuex";
import log from "@/log";
import bus from "@/bus";
import DataRenderCanvas from "./DataRenderCanvas";
import DataRenderToolbar from "./DataRenderToolbar";

export default {
  name: "data-editor",
  components: { DataRenderCanvas, DataRenderToolbar },

  data() {
    return {
      showUploader: null,
      isAppendImport: false,
      grid: null,
      fontSizee: "30px",
    };
  },
  computed: {
    ...mapGetters({
      authData: "app/auth/authData",
      selectedRow: "app/home/selectedRow",
    }),
  },
  watch: {},
  mounted() {},
  methods: {},
  beforeCreate() {},
};
</script>
<style>
.renderpane {
  max-height: calc(100vh - 5rem);
  width: 100%;
  margin-bottom: 0px;
  padding-bottom: 0px;
  border-bottom: 1px solid #ccc;
}
</style>