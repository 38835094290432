export default {
  getLoginStatus(state) {
    return state.loginStatus;
  },
  authData(state) {
    return state.authData;
  },
  me(state) {
    return state.me;
  },
};
